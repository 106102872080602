<template>
  <div>
    <!--zh-cn-->
    <div v-if="lang == 'zh_CN'">
      <section class="wrapper">
        <h1 id="tos">GoodX Wallet服务协议</h1>
        <p><strong>最近更新于：2023年05月08日</strong></p>
        <p><strong>GoodX 钱包应用程序由 Vaultech Ltd 发布，您可以通过cs@goodtrade.io与我们联系</strong></p>
        <p><strong>尊敬的用户：</strong></p>
        <p class="mb">
          <strong>感谢您选择GoodX Wallet 服务。《GoodX Wallet 服务协议》（以下简称“本协议”）由Vaultech
            Ltd（以下简称「本公司」）和您（以下简称「用户」）签订，本协议在您与本公司之间具有合同上的法律效力。在本协议中：</strong>
        </p>
        <p><strong>（1）「我们」和「我们」指代本公司，「我们的」应据此解释；及（2）「您」指代用户，「您的」应据此解释。您和本公司单独称为「一方」，合称为「双方」。 本公司在此特别提醒您在使用我们的GoodX
          Wallet移动应用（以下简称「GoodX Wallet」 或「本App」，GoodX Wallet可在各移动应用平台上下载，包括但不限于Google Play和Apple App
          Store）之前，请认真阅读本协议及后文提及的相关协议，尤其是本协议中「免责及责任限制」等以加粗形式体现的条款，确保您充分理解本协议中各条款，并自主考虑风险。</strong></p>
        <section class="terms-area strong">
          <h2>一、 关于本协议的确认与接纳</h2>
          <ul>
            <li>1.您理解本协议及有关协议适用于GoodX Wallet上本公司所自主开发和拥有的去中心化应用（简称「DApp」）。</li>
            <li>2.您下载GoodX Wallet App并创建身份、恢复身份（定义见下文）或导入钱包，即视为您已经充分阅读并接受本协议全部条款，本协议立即生效，对双方具有约束力。
              <em><span class="decoration">如果您不同意本协议条款，您应立即停止使用GoodX Wallet。如果您已经下载了GoodX Wallet，请立即删除。</span></em></li>
            <li>在浏览或使用GoodX Wallet时, 您同意：
              <ul>
                <li class="deepest-list">（1）接受本协议最新版本的约束（不变更亦不修改）；</li>
                <li class="deepest-list">（2）在您所适用的司法管辖区域内，您已达到使用GoodX Wallet的法定年龄，并可承担因使用GoodX Wallet而产生的有约束力法律或金钱义务；且
                </li>
                <li class="deepest-list">（3）您不属于被排除人士（如本协议所定义）。</li>
              </ul>
            </li>
            <li>4.本协议可由本公司随时更新，经修改的协议一经在GoodX Wallet上公布，立即自动生效，不再另行通知。在本公司公布修改协议条款后，如果您不接受修改后的条款，请立即停止使用GoodX
              Wallet，您继续使用GoodX Wallet将被视为接受修改后的协议。
            </li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>二、 定义</h2>
          <ul>
            <li>1.<strong>GoodX Wallet：</strong> 指由本公司基于区块链开发的虚拟货币钱包，包括其他为方便用户使用区块链系统而开发的辅助工具。</li>
            <li>2.<strong>被排除人士：</strong>
              <ul>
                <li class="deepest-list"><strong>（1）除了自然人以外的、具备订立本协议的法律和意识能力的人士；或</strong></li>
                <li class="deepest-list">
                  <strong>（2）因本协议、法律、监管要求或适用于该用户的司法管辖区的规定而被以任何形式或方式（全部或部分）禁止、限制、无授权或无资格使用服务（如本协议所定义）的用户。为免疑义，中国用户亦涵盖在「被排除人士」的定义内。</strong>
                </li>
              </ul>
            </li>
            <li>3.<strong>身份：</strong> 指基于您的公私钥对生成的数字识别身份。</li>
            <li>4.<strong>新手指南：</strong> 指在您使用GoodX Wallet 之前（及在将来的使用过程中），本公司为您提供的GoodX Wallet 操作指南，以帮助您了解区块链的基础知识。</li>
            <li>5.<strong>创建或导入钱包：</strong> 指在您接受本协议后，使用GoodX Wallet 创建或导入钱包的过程。</li>
            <li>6.<strong>钱包密码：</strong>
              指您在创建GoodX Wallet钱包过程中，由您决定的密码，该密码将被用于加密和保护您的私钥。 GoodX
              Wallet作为去中心化的应用，钱包密码不存储在您的这台移动设备或本公司的服务器，一旦您丢失或忘记钱包密码，您需要借助私钥或助记词重置钱包密码。
            </li>
            <li>7.<strong>信息提示：</strong> GoodX Wallet App操作界面涉及的信息提示内容，建议用户按照相关步骤进行操作</li>
            <li>8.<strong>特定用户：</strong> 指按照英国和其他国家的法律法规及政策规定必须要配合本公司履行个人信息披露义务的用户。</li>
            <li>9.<strong>开发者用户：</strong> 指按照本公司通知及相关开源协议规定使用GoodX Wallet开源代码和开发者模式等针对开发者提供的服务的用户。</li>
            <li>10.<strong>私钥：</strong> 由256位随机字符构成，是用户拥有并使用数字代币的核心。</li>
            <li>11.<strong>公钥：</strong> 由私钥借助密码学原理单向推导生成，并用以生成区块链数字钱包地址，数字钱包地址即为公开收款地址。</li>
            <li>12.<strong>助记词：</strong> 符合区块链BIP39 行业标准，由随机算法生成的12（或15/18/21/24）个有序单词组成。是私钥的易记录表现形式，方便用户备份保管。</li>
            <li>13.<strong>Keystore:</strong> 是私钥或助记词经过用户设置的钱包密码加密保存的文件形式，它只存储在您的这台移动设备中，不会同步至本公司服务器。</li>
            <li>14.<strong>数字代币：</strong> 指GoodX Wallet 目前支持的数字代币种类，包括但不限于BTC、ETH、DGD等。</li>
            <li>15.<strong>资料：</strong> 指GoodX Wallet
              上“浏览”、“市场”等版块的文章等资料，这些资料均为第三方所有，未经授权不得转载。这些资料仅供您参考，并不构成本公司对您交易的指导或建议。
            </li>
            <li>16.<strong>消息中心：</strong> 指用户可以接收本公司发出的信息或通知的收件箱。</li>
            <li>17.<strong>个人信息：</strong>
              指以电子或者其他方式记录的能够单独或者与其他信息结合识别用户个人身份的各种信息，包括但不限于自然人的姓名、出生日期、身份证件号码、个人生物识别信息、住址、电话号码、银行卡号、邮件地址、钱包地址、移动设备信息、操作记录、交易记录等，但不包括用户的钱包密码、私钥、助记词、Keystore。
            </li>
            <li>18.<strong>智能合约：</strong>
              指一种旨在以信息化方式传播、验证或执行合同的、基于以太坊的智能化合约，包括但不限于后文提及的智能合约Kyber和/或智能合约0x。GoodX Wallet 集成的智能合约目前尚不提供跨链服务。
            </li>
            <li>19.<strong>GoodX Wallet 开源代码：</strong> 指本公司公开宣布开源的部分GoodX Wallet
              软件代码，允许开发者用户在遵循相关开源协议及本公司通知的规定下使用（包括二次开发）该部分开源代码。
            </li>
            <li>20.<strong>开源协议：</strong> 指开发者用户使用GoodX Wallet 开源代码所应遵循的第三方开源协议。</li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>三、 GoodX Wallet 服务（统称“服务”）</h2>
          <ul>
            <li>1.<strong>创建和恢复身份</strong>：您可以使用GoodX Wallet 提供的“创建身份”、“恢复身份”按键进行身份的创建和恢复，并能在一个身份下，同时管理多链钱包。</li>
            <li>2.<strong>导入钱包</strong>：对GoodX 支持的数字代币，您可以使用GoodX 生成新钱包或导入相关区块链系统的其它钱包工具生成的兼容钱包。</li>
            <li>3.<strong>转账、收款</strong>。您可以使用GoodX Wallet
              的转账、收款功能进行数字代币的管理，即运用私钥进行电子签名，对相关区块链的账本进行修改。转账是指付款方利用收款方的ENS域名或区块链地址进行转账操作，该“转账”行为涉及在相关区块链系统的分布式账本中对该交易的有效记录（而非在GoodX
              Wallet上实际交付或转让数字代币）。
            </li>
            <li>4.<strong>管理数字代币</strong>：您可以从GoodX Wallet 操作界面添加、保管或移除GoodX Wallet 所支持的数字代币（ETH、BTC除外）。</li>
            <li>5.<strong>闪兑（IEX）</strong>：用户之间可以在第三方智能合约Kyber上发生并完成的数字代币兑换，由GoodX Wallet
              作为界面工具帮助用户和第三方智能合约交互并显示该用户之间兑换的相应结果。
            </li>
            <li>6.<strong>币币兑换（DEX）</strong>：用户可以与使用第三方智能合约0x的第三方进行数字代币兑换，由GoodX 作为界面工具帮助用户和第三方智能合约交互并显示该数字代币兑换的相应结果。
            </li>
            <li>7.<strong>浏览DApp</strong>：用户通过GoodX Wallet 访问并使用该DApp。</li>
            <li>8.<strong>搜索DApp</strong>：用户通过GoodX Wallet 上的搜索框，可以对已在 GoodX Wallet 罗列的DApp进行搜索。</li>
            <li>9.<strong>集成硬件钱包</strong>：用户可以使用合适版本的GoodX Wallet 与特定的硬件钱包进行匹配，并利用硬件钱包确认通过GoodX Wallet 所进行的交易。</li>
            <li>10.<strong>生物辨识支付</strong>：用户可以使用GoodX
              中的免密支付功能，免密支付将用户钱包密码通过安全加密算法存储至用户手机设备的Keychain/Keystore中，交易时调用用户的生物识别（指纹或面容）鉴权，快速完成支付与签名。
            </li>
            <li>11.<strong>开发者模式</strong>：为方便开发者用户，在开发者模式下开发者用户可以通过搜索框键入URL而 浏览任何其他网站。</li>
            <li>12.<strong>交易记录</strong>：我们将通过区块链系统拷贝您全部或部分的交易记录。但用户应以区块链系统的最新交易记录为准。</li>
            <li>13.<strong>暂停服务</strong>：您知悉，基于区块链系统交易“不可撤销”的属性，我们不能为您撤回或撤销交易操作，但在一定情况下，我们可以暂停或者限制某位用户对GoodX Wallet
              软件的操作。
            </li>
            <li>14.其他本公司认为有必要提供的服务。</li>
          </ul>
          <p><strong><span class="decoration">用户接受本公司提供的上述服务时了解并接受</span></strong>：</p>
          <ul>
            <li>
              <p>1.<strong>秉承着区块链的去中心化特点，并为了保护您数字代币安全，本公司提供的是去中心化服务，大大区别于银行业金融机构。用户了解并接受，本公司 <em>不承担</em> 以下责任：</strong>
              </p>
              <ul>
                <li class="deepest-list">（1）<strong>存储用户的钱包密码（即用户创建/导入钱包时设置的密码）、私钥、助记词或Keystore；</strong></li>
                <li class="deepest-list">（2）<strong>找回用户的钱包密码、私钥、助记词或Keystore；</strong></li>
                <li class="deepest-list">（3）<strong>冻结钱包；</strong></li>
                <li class="deepest-list">（4）<strong>挂失钱包；</strong></li>
                <li class="deepest-list">（5）<strong>恢复钱包；或</strong></li>
                <li class="deepest-list">（6）<strong>交易回滚。</strong></li>
              </ul>
            </li>
            <li>
              <p>2.<strong>您应当自行承担保管含有GoodX Wallet的移动设备、备份GoodX Wallet、备份钱包密码、助记词、私钥及Keystore的责任。如您遗失移动设备、删除且未备份GoodX
                Wallet、删除且未备份钱包、钱包被盗或遗忘钱包密码、私钥、助记词或Keystore，本公司均无法还原钱包或找回钱包密码、私钥、助记词或Keystore；如用户进行交易时误操作（例如输错转账地址、输错兑换数额），本公司无法取消交易，且本公司亦不应对此承担任何责任。</strong>
              </p>
            </li>
            <li>
              <p>3.<strong>如您使用GoodX
                Wallet的生物辨识支付功能，请您妥善备份钱包密码。当您的指纹或面容识别多次验证失败之后，您将需要输入自己的钱包密码。如果您遗忘钱包密码的，您需要通过导入助记词/私钥的方式，设置新的钱包密码。本公司不存储您的指纹、面容识别等生物讯息，且本公司亦不应对此承担任何责任。</strong>
              </p>
            </li>
            <li>
              <p>4.<strong>本公司和GoodX Wallet所能够提供的虚拟货币管理服务并未包括所有已存在的虚拟货币，请勿通过GoodX Wallet操作任何GoodX
                Wallet不支持的虚拟货币。</strong></p>
            </li>
            <li>
              <p>5.<strong>用户在GoodX Wallet上使用开发者模式通过输入URL 浏览其他网站或未在GoodX
                Wallet集成的第三方DApps的，应自行了解URL链接的网站和第三方DApps可能存在的安全隐患，并独立承担所有的风险和后果。 </strong>
              </p>
            </li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>四、 您的权利义务</h2>
          <h3>（一）创建或导入钱包</h3>
          <ul>
            <li>1.<strong>创建或导入钱包：</strong>
              您有权在您的移动设备上通过GoodX Wallet创建和/或导入钱包，有权设定钱包的钱包密码等信息，并有权通过GoodX Wallet应用程序，使用自己的钱包在区块链上进行转账和收款等交易。
            </li>
            <li>2.<strong>身份验证：</strong>
              按照有关法律法规的要求，特定用户在使用GoodX Wallet提供的有关服务时，应当按照GoodX
              Wallet的提示及时完成相关身份验证，要求您提交包括但不限于您的姓名、身份证号码、手机号码、银行卡号信息等个人信息。否则该特定用户将无法使用有关服务，因特定用户拖延造成的损失由您自行承担。
            </li>
            <li>本公司可能为不同的终端设备开发不同的
              App版本，您应当根据实际需要选择下载合适的版本进行安装。如果您从未经合法授权的第三方获取本App或与本App名称相同的安装程序，本公司将无法保证该App能否正常使用，也无法保证其安全性，因此造成的损失由您自行承担。
            </li>
            <li>4.本 App新版本发布后，旧版 App可能无法使用。本公司不保证旧版 App的安全性、继续可用性及提供相应的客户服务。请用户随时核对并下载最新版本。</li>
          </ul>
          <h3>（二）使用</h3>
          <ul>
            <li>1.<strong>您应自行承担妥善保管移动设备、钱包密码、私钥、助记词和Keystore等信息的责任。</strong>
              本公司不负责为用户保管以上信息。<strong>因您遗失移动设备或硬件钱包、主动或被动泄露、遗忘钱包密码、私钥、助记词、Keystore或遭受他人攻击、诈骗等所引起的一切风险、责任、损失、费用应由您自行承担</strong>。
            </li>
            <li>2.<strong>GoodX Wallet 信息提示。</strong> 您了解并同意遵循本公司在GoodX
              Wallet上发布的信息提示，按照信息提示的内容进行操作，否则，由此引起的一切风险、责任、损失、费用等应由您自行承担。
            </li>
            <li>3.<strong>您知悉并理解GoodX Wallet没有义务对第三方服务履行尽职调查义务，您应当谨慎评估并承担所有与使用GoodX Wallet有关的风险。</strong></li>
            <li>4.<strong>提供信息和文件。</strong>
              如果本公司自行认为有必要获取用户的相关信息以遵循任何与使用或操作GoodX
              Wallet相关的适用法律或法规的规定，用户应按照本公司的要求及时向本公司提供该等信息，且用户了解并接受，本公司可以限制、暂停或终止您使用GoodX
              Wallet直到您提供满足公司要求的信息。用户承诺及时向本公司告知任何有关其依据本协议向本公司提供的文件和信息中的任何变化，且在没有通知任何变化的书面通知的情形下，本公司有权认为由用户提供的文件和信息的内容是真实、正确、没有误导信息且没有发生改变的。
            </li>
            <li>5.<strong>完成身份验证。</strong>
              当本公司合理认为您的交易行为或交易情况出现异常的，或认为您的身份信息存在疑点的，或本公司认为应核对您身份证件或其他必要文件的情形时，请您积极配合本公司核对您的有效身份证件或其他必要文件，及时完成相关的身份验证。
            </li>
            <li>6.<strong>转账。</strong>
              <ul>
                <li class="deepest-list">（1）<strong>您知悉对于GoodX Wallet 服务中您可使用的日计转账限额和笔数，可能因为您使用该转账服务时所处的国家/地区、监管要求、转账目的、GoodX
                  Wallet 风险控制、身份验证等事由而不同。</strong>
                </li>
                <li class="deepest-list">（2）<strong>您理解基于区块链操作的“不可撤销”属性，当您使用GoodX Wallet
                  转账功能时，您应当自行承担因您操作失误而导致的后果（包括但不限于因您输错转账地址、您自身选择转账节点服务器的问题）。</strong>
                </li>
                <li class="deepest-list">（3）<strong>您知悉在使用GoodX Wallet服务时，以下情况的出现可能导致转账功能不可用、转账“交易失败”或“打包超时”：</strong>
                  <ul>
                    <li class="deepest-list">a) <strong>钱包余额不足；</strong></li>
                    <li class="deepest-list">b) <strong>交易矿工费不足；</strong></li>
                    <li class="deepest-list">c) <strong>区块链执行智能合约代码失败；</strong></li>
                    <li class="deepest-list">d) <strong>超出监管部门、GoodX Wallet 或法律法规规定的付款额度；</strong></li>
                    <li class="deepest-list">e) <strong>网络、设备等技术故障；</strong></li>
                    <li class="deepest-list">f) <strong>区块链网络拥堵、故障等原因引起交易被抛弃；</strong></li>
                    <li class="deepest-list">g) <strong>您的地址或交易对方地址被识别为特殊地址，如高风险地址。</strong></li>
                  </ul>
                </li>
                <li class="deepest-list">（4）<strong>您知悉GoodX 仅向您提供转账工具，在您使用GoodX Wallet
                  完成转账后，本公司即完成了当次服务的所有义务，本公司对其他相关纠纷争议，不承担任何责任。</strong>
                </li>
              </ul>
            </li>
            <li>7.<strong>闪兑和币币兑换。</strong> 您知悉，闪兑和币币兑换的每日限额目前由第三方智能合约限定。本公司亦可能对用户每日可闪兑或币币兑换的额度进行限制并不时调整。</li>
            <li>8.<strong>合法合规。</strong> 您知悉在GoodX Wallet 进行操作时或利用GoodX Wallet上的DApp、智能合约进行交易时，您应当遵循有关法律法规、国家政策的要求。</li>
            <li>9.<strong>公告通知。</strong>
              GoodX Wallet 会以网站公告、电子邮件、发送短信、电话、消息中心信息、弹窗提示或客户端通知等方式向您发送通知，例如通知您交易进展情况，或者提示您进行相关操作，请您及时予以关注。
            </li>
            <li>10.<strong>服务费用与纳税义务：</strong>
              <ul>
                <li class="deepest-list">（1）<strong>您使用GoodX Wallet的闪兑和币币兑换服务时，第三方智能合约会向您收取手续费和/或服务费，具体费用以第三方智能合约收取数额为准，GoodX Wallet 信息提示仅供参考；</strong>
                </li>
                <li class="deepest-list">（2）本公司暂时不向您收取任何形式的服务费或手续费，将来需对某些服务进行收费时本公司将另行约定或公布规则；</li>
                <li class="deepest-list">（3）<strong>您使用GoodX Wallet 进行转账时应支付“矿工费”或网络费，金额由您自行决定，并由相关区块链网络收取；</strong></li>
                <li class="deepest-list">（4）<strong>您知悉，在特定情况（包括但不限于您在转账期间为转账支付的“矿工费”或网络费不足，或相关区块链网络不稳定）下，您的转账操作可能失败；在该等情况下，即便您的转账操作未完成，您亦会被相关区块链网络收取矿工费；</strong>
                </li>
                <li class="deepest-list">（5）您因在GoodX Wallet 进行交易而发生的所有应纳税负及其它方面的费用均由您负责支付。</li>
              </ul>
            </li>
          </ul>
          <h3>（三）开发者用户基于GoodX Wallet 开源代码的开发</h3>
          <ul>
            <li>1.<strong>开发者用户有权使用本公司在官方渠道公布的GoodX Wallet开源代码进行应用程序的二次开发，并下载和使用本公司针对GoodX Wallet开源代码发布的任何补丁或漏洞解决方案。</strong></li>
            <li>2.在使用GoodX Wallet开源代码时，开发者用户知悉并同意：
              <ul>
                <li class="deepest-list">（1）如开发者用户在使用GoodX Wallet开源代码过程中发现任何漏洞、缺陷或软件改进方案的，应及时联系并告知本公司，同时停止任何具有危害性的使用行为（如适用）；
                </li>
                <li class="deepest-list">（2）GoodX Wallet
                  开源代码中可能包含第三方开源协议和源代码，本公司不对该等第三方开源协议和源代码的功能、无病毒危害、无漏洞等提供任何形式的担保。开发者用户在使用GoodX Wallet
                  开源代码时应自行判断和评估使用后果。开发者用户应仔细阅读并遵循相关开源软件协议（包括但不限于Apache协议http://www.apache.org/licenses/LICENSE-2.0.html）及本公司不时作出的通知的要求。
                </li>
              </ul>
            </li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>五、 风险提示</h2>
          <ul>
            <li>1.<strong>您了解并知悉，由于虚拟货币领域的法律法规政策尚未健全，该领域的虚拟货币可能会产生无法兑现、技术不稳定等重大风险。您也了解虚拟货币的价格波动幅度远高于其他金融资产。我们谨慎提醒您应当根据自身财务状况和风险偏好，以合理的方式选择持有或处置任何一种虚拟货币。您也了解GoodX Wallet提供的行情查看功能仅系抓取部分交易所的虚拟货币汇率信息的搜索结果，并不表示为最新行情或最佳报价。</strong>
            </li>
            <li>2.<strong>在使用GoodX Wallet服务时，若您或您的相对方未遵从本协议或相关网站说明、交易、支付页面中之操作提示、规则，本公司并不保证交易会顺利完成，且本公司不承担损害赔偿责任。若发生前述情形，而款项已先行入账您的或您的交易方的GoodX Wallet钱包或第三方钱包，您理解区块链操作具有的「不可逆」属性，以及相关交易具有「不可撤销」的特征，由您及您的相对方自行承担相应的风险后果。</strong>
            </li>
            <li>3.<strong>当您将虚拟货币转给其他钱包地址时，您在交易过程中应当自行判断对方是否为完全民事行为能力人并自行决定是否与对方进行交易或转账给对方等。</strong>
            </li>
            <li>4.<strong>在转账过程中，如果出现「交易失败」、「打包超时」等类似的异常信息提示时，您应通过相关区块链系统的官方途径或其他的区块链查询工具进行再次确认，以避免重复转账；否则，由此所引起的一切损失和费用应由您自行承担。 </strong>
            </li>
            <li>5.<strong>您理解当您在GoodX Wallet上创建或导入钱包之后，您的Keystore、私钥、助记词等信息仅存储在当前的这台移动设备中，不存储在GoodX Wallet或本公司的服务器上。您可以按照GoodX Wallet提供的操作指南采取同步钱包等方式更换移动设备。但若您未保存或备份钱包密码、私钥、助记词、Keystore等信息且在您移动设备丢失的情况下，您的数字代币将因此丢失，本公司无法为您找回。若您在导出、保存或备份钱包密码、私钥、助记词、Keystore等信息的时候泄密，或保存或备份上述信息的设备或服务器被黑客攻击或控制等情况下，您的虚拟货币将因此丢失，本公司无法为您找回。因前述情形产生的任何及所有损失应由您自行承担。</strong>
            </li>
            <li>6.<strong>我们建议您在创建或导入钱包时对您钱包的钱包密码、私钥、助记词及Keystore等信息做好安全备份。我们提请您注意，请不要采用以下电子备份方式：截图、邮件、手机中的记事本应用、短信、微信、QQ等电子备份方式。我们建议您在纸质记事本上抄写助记词和Keystore等信息，同时您亦可将电子数据保管至密码管理器。</strong>
            </li>
            <li>7.<strong>我们建议您在选购硬件钱包时应：</strong>
              <ul>
                <li class="deepest-list">（1）<strong>确认您购买的硬件钱包是否与GoodX Wallet版本相匹配；及</strong></li>
                <li class="deepest-list">（2）<strong>从官方渠道选购全新的硬件钱包。</strong></li>
              </ul>
            </li>
            <li>8.<strong>我们善意提醒您谨慎使用GoodX Wallet的免密支付功能，建议您在使用前应：</strong>
              <ul>
                <li class="deepest-list">（1）<strong>仔细了解您手机设备生物识别的安全等级；</strong></li>
                <li class="deepest-list">（2）<strong>请勿在公共手机中开启免密支付功能；及</strong></li>
                <li class="deepest-list">（3）<strong>如果钱包中的数字资产较大，请勿开启免密支付功能。</strong></li>
              </ul>
            </li>
            <li>9.<strong>我们建议您在安全的网络环境中使用GoodX Wallet，确保您的移动设备没有越狱或root， 以避免可能存在的安全隐患。</strong></li>
            <li>10.<strong>请您在使用GoodX Wallet过程中，警惕诈骗行为。一旦发现任何可疑行为，我们鼓励您第一时间告知我们。</strong></li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>六、 服务的变更、中断、终止</h2>
          <ul>
            <li>1.<strong>您了解并同意本公司可以自行决定暂时提供部分服务功能，或于将来暂停部分服务功能或开通新的服务功能。当我们改变服务时，只要您仍然使用GoodX Wallet，表示您仍然同意本协议或者本协议修正后的条款。</strong>
            </li>
            <li>2.<strong>为了尽可能避免任何对GoodX Wallet的误操作或虚拟货币的任何安全风险，您应避免在不具备区块链基础知识的前提下使用GoodX Wallet。对不具备区块链基础知识的用户，本公司有权拒绝提供部分或全部的服务功能。</strong>
            </li>
            <li>3.<strong>您理解存在如下情形时，本公司将暂停提供服务（或在发生如下情形时彻底终止服务）：</strong>
              <ul>
                <li class="deepest-list">（1）<strong>因设备、区块链系统维修、升级、故障和通信中断等技术原因而中断GoodX Wallet的运营；</strong></li>
                <li class="deepest-list">（2）<strong>因台风、地震、海啸、洪水、停电、战争或恐怖袭击等不可抗力因素，电脑病毒、木马、黑客攻击、系统不稳定或政府行为等原因，造成本公司不能提供服务或本公司合理认为继续提供服务会有较大风险的；</strong>
                </li>
                <li class="deepest-list">（3）<strong>适用法律或政策发生重大不利变化的；或</strong></li>
                <li class="deepest-list">（4）<strong>发生本公司无法控制或合理预见的其他情形。</strong></li>
              </ul>
            </li>
            <li>4.<strong>当用户出现如下情况时，本公司可单方面中止或终止用户使用GoodX Wallet的部分或全部功能:</strong>
              <ul>
                <li class="deepest-list">（1）<strong>用户死亡；</strong></li>
                <li class="deepest-list">（2）<strong>盗用他人的钱包信息或移动设备；</strong></li>
                <li class="deepest-list">（3）<strong>在GoodX Wallet填写虚假个人信息；</strong></li>
                <li class="deepest-list">（4）<strong>拒绝GoodX Wallet的强制更新操作；</strong></li>
                <li class="deepest-list">（5）<strong>违反第三方开源协议或本公司通知的规定使用GoodX Wallet开源代码；</strong></li>
                <li class="deepest-list">（6）<strong>将GoodX Wallet用于违法或犯罪活动；</strong></li>
                <li class="deepest-list">（7）<strong>妨碍其他用户正常使用GoodX Wallet；</strong></li>
                <li class="deepest-list">（8）<strong>伪称本公司的工作人员或管理人员；</strong></li>
                <li class="deepest-list">（9）<strong>攻击、侵入、更改或以任何其他方式威胁本公司计算机系统的正常运作；</strong></li>
                <li class="deepest-list">（10）<strong>利用GoodX Wallet宣传垃圾广告；</strong></li>
                <li class="deepest-list">（11） <strong>散布谣言，损害本公司和GoodX Wallet商誉；或</strong></li>
                <li class="deepest-list">（12） <strong>违法行为，其他违反本协议的行为，及本公司合理认为应当暂停功能的情形。</strong></li>
              </ul>
            </li>
            <li>5.<strong>如果本公司变更、中断、终止服务时，您有权在合理时间内导出您钱包等信息。</strong></li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>七、 您的陈述与保证</h2>
          <ul>
            <li>1.<strong>您应遵守您所居住的国家或地区的法律法规，不得将GoodX Wallet用于任何非法目的，也不得以任何非法方式使用GoodX Wallet。</strong></li>
            <li>2.<strong>您不属于无资格使用GoodX Wallet服务的被排除人士。</strong></li>
            <li>3.<strong>您不得利用GoodX Wallet从事任何违法或犯罪的行为，包括但不限于：</strong>
              <ul>
                <li class="deepest-list">（1）<strong>从事任何违法犯罪行为，包括但不限于洗钱、非法集资等；</strong></li>
                <li class="deepest-list">（2）<strong>通过使用任何自动化程序、软件、引擎、网络爬虫、网页分析工具、数据挖掘工具或类似工具等，接入本公司服务、收集或处理本公司所提供的内容，干预或试图干预任何用户；</strong>
                </li>
                <li class="deepest-list">（3）<strong>提供赌博资讯或以任何方式引诱他人参与赌博；</strong></li>
                <li class="deepest-list">（4）<strong>侵入他人GoodX Wallet钱包盗取数字代币；</strong></li>
                <li class="deepest-list">（5）<strong>进行与交易对方宣称的交易内容不符的交易，或不真实的交易；</strong></li>
                <li class="deepest-list">（6）<strong>从事任何侵害或可能侵害GoodX Wallet服务系统、数据的行为；</strong></li>
                <li class="deepest-list">（7）<strong>其他本公司有正当理由认为不适当的行为。</strong></li>
              </ul>
            </li>
            <li>4.<strong>您理解并同意，如因您违反有关法律（包括但不限于海关及/或税务方面的监管规定）或者本协议之规定，使本公司遭受任何损失、受到任何第三方的索赔或任何行政管理部门的处罚，您应对本公司进行赔偿，包括合理的律师费用。</strong>
            </li>
            <li>5.<strong>您承诺按时缴纳本公司的服务费用（如有），否则本公司有权暂停对您提供的服务。</strong></li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>八、 隐私政策</h2>
          <ul>
            <li>1.您提供给我们的任何数据或信息亦受我们隐私政策（“<strong>《GoodX Wallet 隐私政策》</strong>”的约束，该隐私政策亦是本协议的一部分。</li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>九、 免责及责任限制</h2>
          <ul>
            <li>1.<strong>本公司仅对本协议中所列明的义务承担责任。</strong></li>
            <li>2.<em><span class="decoration">您理解和同意，在法律所允许的最大范围内，本公司只能按照现有的技术水平和条件提供GoodX Wallet服务</span></em>。<strong>因下列任何原因导致GoodX Wallet
              无法正常提供服务，本公司不承担责任：</strong>
              <ul>
                <li class="deepest-list">（1）<strong>GoodX Wallet 系统停机维护或升级；</strong></li>
                <li class="deepest-list">（2）<strong>因台风、地震、洪水、雷电或恐怖袭击等不可抗力原因；</strong></li>
                <li class="deepest-list">（3）<strong>您的移动设备软硬件和通信线路、供电线路出现故障的；</strong></li>
                <li class="deepest-list">（4）<strong>您操作不当或未通过本公司授权或认可的方式使用本公司服务的；</strong></li>
                <li class="deepest-list">（5）<strong>因电脑病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行或政府行为等原因；</strong>
                </li>
                <li class="deepest-list">（6）<strong>非因本公司的原因而引起的任何其它原因。</strong></li>
              </ul>
            </li>
            <li>3.<strong>本公司对以下任何情形均不承担责任：</strong>
              <ul>
                <li class="deepest-list">（1）<strong>因您遗失移动设备、删除且未备份GoodX Wallet、删除且未备份钱包、遗忘且未备份钱包密码、私钥、助记词、Keystore而导致该用户的虚拟货币币丢失；</strong>
                </li>
                <li class="deepest-list">（2）<strong>因您自行泄露钱包密码、私钥、助记词、Keystore，或借用、转让或授权他人使用自己的移动设备或GoodX Wallet钱包，或未通过本公司官方渠道下载GoodX Wallet应用程序或以其他不安全的方式使用GoodX Wallet应用程序导致的您的虚拟货币丢失；</strong>
                </li>
                <li class="deepest-list">（3）<strong>因您误操作（包括但不限于您输错转账地址、您自身选择转账节点服务器的问题）GoodX Wallet导致的虚拟货币丢失；</strong>
                </li>
                <li class="deepest-list">（4）<strong>因您不理解区块链技术的知识而进行误操作导致的您的数字代币丢失；</strong></li>
                <li class="deepest-list">（5）<strong>因系统滞后、区块链系统不稳定等原因导致本公司拷贝用户在区块链上的交易记录发生偏差；</strong></li>
              </ul>
            </li>
            <li>4.<strong>每位用户应承担由下列情形产生或与之相关的风险和后果：</strong>
              <ul>
                <li class="deepest-list">（1）<strong>该用户使用第三方服务操作的交易；</strong></li>
                <li class="deepest-list">（2）<strong>用户使用开发者模式</strong></li>
              </ul>
            </li>
            <li>5.<strong>您理解GoodX Wallet仅作为您虚拟货币管理的工具。本公司不能控制第三方服务的质量、安全或合法性，信息的真实性或准确性，以及相对方履行其在与您签订的协议项下的各项义务的能力。您自行决定是否使用第三方服务。您理解该交易和有约束力的合同关系在您和您的相对方之间建立，与本公司无关。本公司提醒您在使用第三方服务前，应谨慎判断第三方服务所提供相关信息的真实性、合法性和有效性。此外，您与任何第三方交易所产生的全部风险亦应由您自行承担。</strong>
            </li>
            <li>6.<strong>您知悉本公司可能同时为您及您的交易对手方提供服务或与之有关联关系或其他利益相关的关系，您同意对本公司可能存在的该等行为予以明确豁免任何实际或潜在的利益冲突，并不得以此来主张本公司在提供服务时存在法律上的瑕疵，也不因此而加重本公司的责任或注意义务。 </strong>
            </li>
            <li>7.<strong>本公司不提供以下形式的保证：</strong>
              <ul>
                <li class="deepest-list">（1）<strong>本公司提供的服务将符合您的全部需求；</strong></li>
                <li class="deepest-list">（2）<strong>您经由本公司服务取得的任何技术、产品、服务、资讯或其他材料将符合您的期望；</strong></li>
                <li class="deepest-list">（3）<strong>本公司从第三方交易所抓取的数字代币市场交易行情等信息的及时性、准确性、完整性、可靠性；</strong></li>
                <li class="deepest-list">（4）<strong>您在GoodX Wallet上的交易对方会及时履行其在与您达成的交易协议中的各项义务。</strong></li>
              </ul>
            </li>
            <li>8.<strong>在任何情况下，本公司对本协议所承担的违约赔偿责任总额不超过1）0.1个以太币的市场价值；或2）美元100元，以较高的为准。</strong></li>
            <li>9.<strong>您理解GoodX Wallet仅作为用户管理虚拟货币、显示交易信息的工具，本公司不提供法律、税务或投资建议等服务。您应当自行向法律、税务、投资方面的专业人士寻求建议，且您在使用我们服务过程中所遭受的投资损失、数据损失等，本公司概不负责。</strong>
            </li>
            <li>10.<strong>您理解根据适用法律法规的要求，我们可能不时更改我们的用户准入标准，限定向特定用户等提供服务的范围和方式等。</strong></li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>十、 其他条款</h2>
          <ul>
            <li>1.<strong>不得转让。</strong>
              在符合本协议条款的情况下，只有您（而非其他人）有权向我们主张与使用服务相关的索赔。您不得转让、交易，或试图转让、交易您对该索赔的权利。任何该等转让行为均为无效，且我们对受让方不承担任何义务或责任。
            </li>
            <li>2.<strong>知识产权。</strong>GoodX Wallet系本公司开发并拥有知识产权的应用程序。 GoodX Wallet中显示的任何内容（包括本协议、公告、文章、视频、音频、图片、档案、资讯、资料、商标或标识）的知识产权归本公司或第三方权利人所有。用户仅可为持有和管理虚拟货币之目的使用GoodX Wallet应用程序及其中的内容。未经本公司或第三方权利人的事先书面同意（或相关开源协议的允许），任何人不得擅自使用、修改、反向编译、复制、公开传播、改变、散布、发行或公开发表上述应用程序及内容（包括GoodX Wallet开源代码）。本协议不应被视为授予您任何知识产权，包括出于任何目的使用与我们或服务相关的任何信息、图片、用户界面、标识、商标、商业名称、网络域名或版权的权利。
            </li>
            <li>3.<strong>非弃权。</strong> 任何我们未执行本协议或未向您主张我们在本协议项下的权利、索赔或诉因的行为均不被视为放弃我们向您主张任何权利、索赔或诉因的权利。</li>
            <li>4.<strong>完整协议：</strong>
              <ul>
                <li class="deepest-list">（1）本协议由
                  <em><strong>《GoodX Wallet 服务协议》</strong></em>、<em><strong>《GoodX Wallet 隐私政策》</strong></em>
                  及本公司不时公布的各项规则（包括“帮助中心”的内容）组成。
                </li>
                <li class="deepest-list">（2）除了本协议中的 <em><strong>《GoodX Wallet 服务协议》</strong></em>
                  、<em><strong>《GoodX Wallet 隐私政策》</strong></em> 外，本协议包含我们之间关于使用GoodX Wallet
                  和相关服务的全部协议和谅解，并取代所有先前的（口头的和书面的）协议、谅解或安排。
                </li>
              </ul>
            </li>
            <li>5.<strong>税款：</strong>
              <ul>
                <li class="deepest-list">（1）使用本协议下的服务不含您在任何法域就您使用本服务所适用、产生、有关的税款（<strong>“应纳税款”</strong>）。</li>
                <li class="deepest-list">
                  （2）您应负责确定任何应纳税款，并向合适的税务机关申报、代扣、收集、报告和代缴正确的应纳税款。您应自行承担因未完成或未履行您的任何与应纳税款相关的义务而产生的所有处罚、索赔、罚款、惩罚和其他责任。
                </li>
                <li class="deepest-list">（3）我们不负责确定任何应纳税款，亦不负责向合适的税务机关申报、代扣、收集、报告和代缴正确的应纳税款。</li>
              </ul>
            </li>
            <li>6.<strong>法律适用和争议解决：</strong>
              <ul>
                <li class="deepest-list">（1）本协议适用英国法律，并应据此解释。</li>
                <li class="deepest-list">
                  （2）因本协议引起的或与之相关的任何争议（包括任何有关其存在、有效性、终止的问题），双方应首先寻求和解。如果该争议在该和解程序开始后的30天内仍未解决，双方应将该争议提交至英国法院。
                </li>
              </ul>
            </li>
            <li>7.<strong>独立性和部分无效：</strong>
              <ul>
                <li class="deepest-list">
                  （1）本协议的任何部分被任何约束本协议的立法认为无效、非法或不可执行时，其仅在该范围（且不得进一步扩展）内被视为无效、非法或不可执行。为避免疑问，本协议的剩余部分将继续有效并保持充分的效力。
                </li>
                <li class="deepest-list">
                  （2）根据任何司法管辖区的法律，本协议任何条款被认定为非法、无效或不可执行的情形不得影响该条款依据其他司法管辖区的法律被认定为合法、有效或可执行，亦不得影响本协议其他条款的合法性、有效性或可执行性。
                </li>
              </ul>
            </li>
            <li>8.<strong>本协议译本</strong>。本协议的任何译本（如有）仅为方便用户而提供，无意对本协议的英文版本进行修改。如果本协议的英文版本与非英文版本之间存在冲突，应以英文版本为准。</li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>十一、 其它</h2>
          <ul>
            <li>1.您应当全面了解并遵守您所在司法辖区与使用服务所有相关法律、法规及规则。</li>
            <li>2.您在使用服务过程中，如遇到任何问题，您可以通过在GoodX Wallet提交反馈等方式联系我们。</li>
            <li>3.所有的用户可以在GoodX Wallet中查看本协议。 本公司鼓励您在每次访问GoodX Wallet时都查阅本协议。</li>
            <li>4.<strong>本协议自2023年5月8日起生效。 </strong></li>
          </ul>
        </section>
        <p class="center last"><strong>本协议未尽事宜，您需遵守本公司不时更新的公告及相关规则。</strong></p>
        <p class="right"><strong>Vaultech Ltd</strong></p>

      </section>
      <section class="wrapper">
        <h1 id="privacy-policy">GoodX Wallet 隐私政策</h1>
        <p class="mb"><strong>最近更新于：2023年5月8日</strong></p>
        <p class="mb"><strong>尊敬的用户：</strong></p>
        <p><strong> Vaultech Ltd（以下简称「本公司」或「我们」）尊重并保护用户（以下简称「您」或「用户」）的隐私，当您进行以下行为时，本公司按照本隐私政策（以下简称「本政策」）收集、披露和处理您的个人信息：</strong></p>
        <p class="mb"><strong>本公司建议您在使用应用之前仔细阅读并理解本政策全部内容, 针对免责声明等条款在内的重要信息将以加粗的形式体现。本政策有关关键词定义与本公司《GoodX Wallet服务协议》保持一致。如果本政策中使用的定义或术语与《GoodX Wallet服务协议》中的不一致，以本政策为准。 您向我们提供您的个人信息，即代表您同意我们根据本政策的规定收集、使用、披露（包括传输）和处理您的个人信息。如您不接受本政策，请勿 向我们提供任何个人信息。 本政策可由本公司在线随时更新，不另行通知。更新后的政策一经公布在我们的应用上即为生效，并代替原来的政策。修改后的政策将立刻适用于您向我们提供的个人信息。如果您不接受修改后的条款，请立即停止使用应用，您继续使用应用将被视为接受修改后的政策。</strong></p>
        <section class="terms-area">
          <h2>一、我们收集您的哪些信息</h2>
          <ul>
            <li>1.我们将收集您的移动设备信息、操作记录、交易记录、钱包地址等个人信息。</li>
            <li>2.为满足您的特定服务需求，我们将收集您的个人信息，包括但不限于您的姓名、银行卡号、手机号码、邮件地址等信息。</li>
            <li>3.<strong>您知悉：您在应用上的钱包密码、私钥、助记词、Keystore并不存储或同步至本公司服务器。本公司不提供找回您的钱包密码、私钥、助记词、Keystore的服务。</strong></li>
            <li>4.我们可能要求向您收集更多的个人信息，以使得您可以使用应用上的某些特定功能。如您不同意提供该等个人信息，则视为您放弃使用应用的特定功能。</li>
            <li>5.<strong>我们收集信息的方式如下：</strong>
              <ul>
                <li><strong class="deepest-list">（1）当您主动向我们提供信息，无论以何种理由；</strong></li>
                <li><strong class="deepest-list">（2）当您授权我们向第三方获取您的信息；</strong></li>
                <li><strong class="deepest-list">（3）您使用我们的应用和服务；</strong></li>
                <li><strong class="deepest-list">（4）当您通过各种交流渠道与我们的员工联系或互动，例如通过社交平台、通讯平台、当面会议、电话、邮件、传真和书信等；</strong></li>
                <li><strong class="deepest-list">（5）当您与我们发生交易、与我们联系或要求我们联系您；</strong></li>
                <li><strong class="deepest-list">（6）当您要求订阅我们的邮件；和/或；</strong></li>
                <li><strong class="deepest-list">（7）我们通过区块链系统，拷贝您全部或部分的交易记录。但您的交易记录应当以区块链系统的记载为准。</strong></li>
              </ul>
            </li>
            <li>6.我们的应用可能包含一些收集个人信息的技术，具体的收集方式将在本政策（见下文第五条）或适用的条款和条件中说明。</li>
            <li>7.您向我们提供个人信息系您的主动行为，您可以随时撤回同意。但是，如您选择拒绝向我们提供我们要求的个人信息，您可能无法使用应用，我们可能无法与您联系或向您提供您需要的产品或服务。</li>
            <li>
              8.在某些情况下，您可能向我们提供非您本人的个人信息。如您提供他人信息，您应当保证您已获得该等信息主体的同意，允许我们收集该信息主体的个人信息，并且信息主体同意您向我们披露其个人信息。您同意，如该等信息主体就我们根据本政策条款收集、使用和披露其个人信息向我们提出索赔，您将向我们进行赔偿并使我们免责。
            </li>
            <li>9.您应确保您向我们提供的个人信息真实、准确和完整。在您的个人信息发生变化时，您应当通知我们。</li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>二、我们如何使用您的信息</h2>
          <ul>
            <li>1.我们将出于以下一个或多个目的收集、使用或披露您的个人信息：
              <ul>
                <li class="deepest-list">（1）向您提供您要求的产品和/或服务；</li>
                <li class="deepest-list">（2）处理您与我们的关系；</li>
                <li class="deepest-list">（3）促进您的使用体验；</li>
                <li class="deepest-list">（4）通过您移动设备的唯一序列号，确认您与您的钱包的对应关系；</li>
                <li class="deepest-list">（5）向您及时发送重要通知，如软件更新、服务协议及本政策条款的变更；</li>
                <li class="deepest-list">（6）通过钱包地址和提供的移动设备信息，协助您处理您的提问、反馈、投诉和要求；</li>
                <li class="deepest-list">（7）告知您我们的产品、服务、项目和活动；</li>
                <li class="deepest-list">（8）解决争议、调查任何投诉、索赔或争议、或任何实际或可以的非法行为；</li>
                <li class="deepest-list">（9）进行本公司内部审计、数据分析和研究；</li>
                <li class="deepest-list">（10）通过跟踪用户对应用的使用情况进行用户行为分析；</li>
                <li class="deepest-list">（11）遵守法律法规规定及与监管机构的要求；</li>
                <li class="deepest-list">（12）遵守国际法律法规对于安全以及反洗钱或反恐的要求；</li>
                <li class="deepest-list">（13）履行我们的义务以及合同条款条件；和/或</li>
                <li class="deepest-list">（14）任何与前述目的有关的合理目的。</li>
              </ul>
            </li>
            <li>2.如您同意，我们将为其他目的不时使用您的个人信息，例如告知您我们的战略合作伙伴或关联方推出的最新的活动、优惠和推广信息。</li>
            <li>3.我们在GoodX Wallet的「使用设置」中为您提供「指纹或面容登录」选项，让您便捷地管理您的虚拟货币。</li>
            <li>4.我们在GoodX Wallet中为您提供「生物辨识支付」选项，生物辨识支付将您钱包密码通过安全加密算法存储至至您手机设备的Keychain/Keystore中，交易时调用您的生物识别（指纹或面容）鉴权，快速完成支付与签名。
            </li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>三、您如何控制自己的信息</h2>
          <p>您在应用中拥有以下对您个人信息自主控制权：</p>
          <ul>
            <li>1.您可以通过同步钱包的方式，将您的其他钱包导入应用中，或者将您在应用的钱包导入到其他数字代币管理钱包中。应用将向您显示导入钱包的信息。</li>
            <li>2.您知悉您可以通过“资产”版块内容修改您的数字代币种类、进行转账及收款等活动。</li>
            <li>3.您知悉在应用“我”的版块您可以自由选择进行如下操作：
              <ul>
                <li class="deepest-list">（1）在“使用设置”中，您可以选择不开启“指纹或面容登录”选项，即您可以选择不使用Touch ID或Face ID验证服务；</li>
                <li class="deepest-list">（2）在“提交反馈”中，您可以随时向我们提出您对应用问题及改进建议，我们将非常乐意与您沟通并积极改进我们的服务。</li>
              </ul>
            </li>
            <li>4.您知悉您可以选择不开启「生物辨识支付」选项，即您可以选择不在交易时调用您的生物识别（指纹或面容）鉴权。</li>
            <li>5.您知悉，您及我们对于您交易记录是否公开并没有控制权，因为基于区块链交易系统的开源属性，您的交易记录在整个区块链系统中公开透明。 </li>
            <li>6.我们的应用将包含链接，可链接至非由我们所有、运营、开发或维护的其他网站、应用或智能合约。这些链接系为您方便而提供。本政策仅适用于我们的应用。当您使用第三方服务时，您知悉，我们的《GoodX Wallet服务协议》、《GoodX Wallet隐私政策》将不再适用。我们建议您详细阅读并了解其隐私规则和有关用户服务协议等内容。
            </li>
            <li>7.您有权要求我们更新、更改、删除您的个人信息和/或撤回您给予我们的统一。如果您希望撤回您曾给予我们的统一，或如您希望更新、更改、删除或浏览我们持有的个人信息，或您不接受我嫩对于本政策的修改，您可以联系官方客服。
            </li>
            <li>8.为使您管理由我们持有的个人信息，或使您获得有关我们在您提出该等请求前一年内的时间使用（或可能使用）您的个人信息的方式，我们可能向您收取一定的费用。如果我们向您收费，我们将提前告知您收费金额，并在您付费之后满足您的请求。我们将尽力在您提出请求后的三十（30）日内答复您。如果我们无法在三十（30）日内答复，我们将告知您具体的答复时间。
            </li>
            <li>9.在许多情况下，我们需要获得您的个人信息，以使得我们可以向您提供您要求的产品或服务。如您选择拒绝向我们提供我们要求的个人信息或撤回曾给予我们的同意，我们可能无法继续向您提供您要求的产品和服务。 </li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>四、我们可能分享或传输您的信息</h2>
          <ul>
            <li>1.我们将为业务和法律的目的存储您的个人信息。</li>
            <li>2.未经过您的同意，我们不会向第三方出售、交易或转让您的个人信息。</li>
            <li>3.如果您同意我们向战略合作方或关联方披露您的个人信息，我们可能将您的个人信息披露给该等实体。该等实体将仅为您已同意的目的使用您的个人信息。</li>
            <li>4.您同意，我们可能向以下第三方披露或共享您的个人信息：
              <ul>
                <li class="deepest-list">
                  （1）代表我们并向我们提供服务的服务提供商和数据处理方，例如为我们进行KYC检查、会计、数据处理或管理服务、网站托管、维护及运营服务、邮件信息服务、分析服务、支付交易处理、市场营销等；和
                </li>
                <li class="deepest-list">（2）我们的咨询师和专业顾问（例如会计师、律师、审计师）。</li>
              </ul>
            </li>
            <li>
              如果为了上述的目的，我们需要将您的个人信息传输至任何其他国家，我们将获得您的同意，并且确保个人信息的接收方具备和我们同等级的个人信息保护措施。如果该等国家或地区尚无可适用于我们与您之间关系的个人信息保护法律，我们将与个人信息接收方签订可合法执行的协议。            </li>
            <li>6.<strong>未经您事先同意，本公司不会将您的个人信息向任何第三方共享或转让，但以下情况除外：</strong>
              <ul>
                <li class="deepest-list"><strong>（1）所收集的个人信息是您自行向社会公众公开的；</strong></li>
                <li class="deepest-list"><strong>（2）所收集的个人信息系从合法公开披露的信息中收集，如合法的新闻报道，政府信息公开等渠道；</strong></li>
                <li class="deepest-list"><strong>（3）根据适用的法律法规、法律程序的要求、行政机关或司法机关的要求进行提供或执行本政策以保护我们或其他方的权利、财产或安全；</strong>
                </li>
                <li class="deepest-list"><strong>（4）在涉及合并、收购时，如涉及到个人信息转让，本公司将要求个人信息接收方继续接受本政策的约束。</strong></li>
              </ul>
            </li>
          </ul>
        </section>
        <section class="terms-area" id="automatic-data-collection">
          <h2>五、自动数据收集技术</h2>
          <ul>
            <li>1.我们在应用中使用自动数据收集技术。例如：
              <ul>
                <li class="deepest-list">
                  （1）Cookies（或浏览器cookies）。Cookies是网站或应用运营方设置的小型文本文件，以识别您的浏览器或设备。我们可能在我们的应用上使用cookies技术存储并跟踪信息，例如用户数量及使用频率、用户分布以及用户的线上偏好。cookies不抓取能够识别您身份的信息，但其收集的信息可协助我们对应用的使用情况进行分析，以提高您的使用体验。您可以在浏览器设置中关闭cookies。但是，这可能影响应用的功能。
                </li>
                <li class="deepest-list">
                  （2）网络分析。网络分析是收集、评估网页和移动应用访问者行为的一种方法。这包括对流量模式进行分析，例如确定网站或移动应用某部分功能的访问频率，或用以了解访问者最感兴趣的信息或服务。我们的应用使用的是第三方工具提供的网络分析服务。
                </li>
              </ul>
            </li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>六、我们如何保护您的信息</h2>
          <ul>
            <li>1.如本公司停止运营，本公司将停止继续收集您个人信息的活动，并采取措施对所持有的您的个人信息在合理期限内进行删除或匿名化处理。</li>
            <li>2.为了保护您的个人信息，本公司将采取数据安全技术措施，提升内部合规水平，增加内部员工信息安全培训，并对相关数据设置安全访问权限等方式安全保护您的隐私信息。</li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>七、对未成年人的保护</h2>
          <p>我们对保护未满18周岁的未成年人做出如下特别约定：</p>
          <ul>
            <li>1.未成年人应当在父母或监护人指导下使用本公司相关服务。</li>
            <li>2.我们建议未成年人的父母和监护人应当在阅读本政策、《GoodX Wallet 服务协议》及我们的其他有关规则的前提下，指导未成年人使用应用。</li>
            <li>3.本应用将根据适用的法律法规的规定保护未成年人的个人信息的保密性及安全性。</li>
          </ul>
        </section>
        <section class="terms-area strong">
          <h2>八、免责声明</h2>
          <ul>
            <li>1.您确认，您使用第三方服务之后，本政策将不再适用于该等第三方对您个人信息的收集、使用、披露和传输行为。本公司无法保证该等第三方会采取合理的安全保护措施。</li>
            <li>2.您将自行对使用第三方服务的行为承担责任，并且您同意，如第三方收集、使用、披露和传输您个人信息，导致您受到损失或损害的，您不得要求本公司承担责任。</li>
            <li>3.<em><span class="decoration"> 您确认并接受，在适用的法律允许的最大限度下，本公司将在现有技术水平条件下，基于「根据现状」、「根据可用」、「不保证无瑕疵」的原则，尽可能采取合理的安全措施保护您的个人信息，以避免信息的泄露、篡改或者毁损。本公司系利用无线方式传输数据，因此，本公司无法确保通过无线网络传输数据的隐私性和安全性。 </span></em>
            </li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>九、其他</h2>
          <ul>
            <li>
              <p>1.您需全面了解并遵守您所在司法辖区与使用本公司服务所有相关法律、法规及规则。</p>
            </li>
            <li>
              <p>2.法律适用和争议解决：本政策适用英国法律，并应据此解释。</p>
            </li>
            <li>
              <p>3.您可以在我们的应用中查看本政策及本公司其他服务规则。我们鼓励您在每次 浏览应用时都查阅本公司的服务协议及隐私政策。 </p>
            </li>
            <li>
              <p>4.本政策的任何译文版本仅为方便用户而提供，无意对本政策的条款进行修改。如果本政策的英文版本与非英文版本之间存在冲突，应以英文版本为准。</p>
            </li>
            <li>
              <p><strong>5.本政策自2023年5月8日起适用。</strong></p>
            </li>
          </ul>
        </section>
        <p class="list center"><strong>本政策未尽事宜，您需遵守本公司不时更新的公告及相关规则。</strong></p>
        <p class="right"><strong>Vaultech Ltd</strong></p>
      </section>
    </div>

    <!--en-->
    <div v-else>
      <section class="wrapper">
        <h1 id="tos">GoodX Wallet Service Agreement</h1>
        <p class="mb"><strong>Last updated on: May 8, 2023</strong></p>
        <p><strong>About GoodX Wallet
          The GoodX Wallet application is published by Vaultech Ltd, and you can contact us at cs@goodtrade.io.
        </strong></p>
        <p class="mb"><strong>Dear User,</strong></p>
        <p class="mb">
          <strong>Thank you for choosing the GoodX Wallet service. The "GoodX Wallet Service Agreement" (hereinafter referred to as the "Agreement") is entered into between Vaultech Ltd (hereinafter referred to as the "Company") and you (hereinafter referred to as the "User"). This Agreement constitutes a legally binding contract between you and the Company. In this Agreement:
            (1) "We" and "us" refer to the Company, and "our" shall be construed accordingly; and
            (2) "You" refers to the user, and "your" shall be construed accordingly. Each of you and the Company shall be referred to individually as a "Party" and collectively as the "Parties". The Company hereby specifically reminds you to carefully read this Agreement and the related agreements mentioned herein, especially the provisions highlighted in bold such as "Disclaimer and Liability Limitations" in this Agreement, to ensure that you fully understand the terms and consider the risks on your own before using our GoodX Wallet mobile application (hereinafter referred to as "GoodX Wallet" or "the App"). GoodX Wallet can be downloaded from various mobile application platforms, including but not limited to Google Play and Apple App Store.
          </strong></p>
        <section class="terms-area strong">
          <h2>I.Confirmation and Acceptance of this Agreement</h2>
          <ul>
            <li>
              <p>1. You understand that this Agreement and the relevant agreements apply to the decentralized applications (referred to as "DApps") developed and owned by the Company on GoodX Wallet.</p>
            </li>
            <li>
              <p>2. By downloading the GoodX Wallet App, creating an identity, recovering an identity (as defined below), or importing a wallet, you are deemed to have fully read and accepted all the terms of this Agreement, and this Agreement will take effect immediately and be binding on both Parties. If you do not agree to the terms of this Agreement, you should immediately stop using GoodX Wallet. If you have already downloaded GoodX Wallet, please delete it immediately.</p>
            </li>
            <li>
              <p>3. By browsing or using GoodX Wallet, you agree that:</p>
              <ul>
                <li class="deepest-list">a) You accept the latest version of this Agreement without modification or amendment;
                </li>
                <li class="deepest-list">b)  In your applicable jurisdiction, you have reached the legal age to use GoodX Wallet and can assume legally binding obligations, whether monetary or legal, resulting from the use of GoodX Wallet; and
                </li>
                <li class="deepest-list">c) You are not a person excluded as defined in this Agreement.</li>
              </ul>
            </li>
            <li>
              <p>4.This Agreement may be updated by the Company at any time. Once the modified agreement is published on GoodX Wallet, it will take effect immediately without further notice. After the modified terms of the Agreement are published by the Company, if you do not accept the modified terms, you should immediately stop using GoodX Wallet. Your continued use of GoodX Wallet will be deemed as acceptance of the modified agreement.</p>
            </li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>II.Definition</h2>
          <ul>
            <li>1. GoodX Wallet refers to the virtual currency wallet developed by the Company based on blockchain, including other auxiliary tools developed for the convenience of users in using blockchain systems.
            </li>
            <li>2.<strong>Excluded Person:</strong>
              <ul>
                <li>a) <strong> Persons other than natural persons who have the legal capacity and awareness to enter into this Agreement; or</strong></li>
                <li>b)
                  <strong>Users who are prohibited, restricted, unauthorized, or unqualified (in whole or in part) to use the service (as defined in this Agreement) due to this Agreement, laws, regulatory requirements, or provisions applicable to the user's jurisdiction. For the avoidance of doubt, Chinese users are also covered by the definition of "Excluded Persons."</strong></li>
              </ul>
            </li>
            <li>3. Identity refers to the digital identification generated based on your public and private keys.
            </li>
            <li>4. User Guide refers to the instructional guide provided by the Company to help you understand the basics of blockchain before and during your use of GoodX Wallet.
            </li>
            <li>5. Create or Import Wallet refers to the process of creating or importing a wallet using GoodX Wallet after accepting this Agreement.
            </li>
            <li>6. Wallet Password refers to the password you choose during the creation of the GoodX Wallet, which is used to encrypt and protect your private keys. As a decentralized application, the wallet password is not stored on your mobile device or the Company's server. If you lose or forget your wallet password, you will need to reset it using your private key or mnemonic phrase.
            </li>
            <li>7. Information Prompts refer to the content displayed on the GoodX Wallet App interface, providing guidance for users to follow the relevant steps.
            </li>
            <li>8. Specific User refers to users who, according to the laws, regulations, and policies of the United Kingdom and other countries, must cooperate with the Company in fulfilling personal information disclosure obligations.</li>
            <li>9. Developer User refers to users who use the GoodX Wallet open-source code, developer mode, and other services provided specifically for developers according to the Company's notifications and relevant open-source agreements.</li>
            <li>10. Private Key is a 256-bit random character string that is owned and used by the user as the core of virtual currency ownership.</li>
            <li>11. Public Key is derived from the private key through cryptographic principles and is used to generate a blockchain digital wallet address, which is the public receiving address.</li>
            <li>12. Mnemonic Phrase is a sequence of 12 (or 15/18/21/24) ordered words generated by a random algorithm in accordance with the industry standard of blockchain BIP39. It is an easily recordable representation of the private key, facilitating user backup and storage.</li>
            <li>13. Keystore refers to the file form in which the private key or mnemonic phrase is encrypted and saved using the wallet password set by the user. It is only stored on your mobile device and is not synchronized with the Company's server.</li>
            <li>14. Digital Tokens refer to the virtual currency types currently supported by GoodX Wallet, including but not limited to BTC, ETH, and others.</li>
            <li>15. Information refers to articles and other materials in sections such as "News" on GoodX Wallet. These materials may not be reproduced without authorization. They are provided for reference purposes only and do not constitute the Company's guidance or advice on your transactions.</li>
            <li>16. Message Center refers to the inbox where users can receive messages or notifications sent by the Company.</li>
            <li>17. Personal Information refers to various information recorded electronically or in other forms that can individually or in combination with other information identify the user's personal identity. This includes but is not limited to the individual's name, date of birth, identification number, personal biometric information, address, phone number, bank card number, email address, wallet address, mobile device information, operation records, transaction records, but does not include the user's wallet password, private key, mnemonic phrase, or Keystore.</li>
            <li>18. Smart Contracts refer to Ethereum-based smart contracts aimed at disseminating, verifying, or executing contracts in an information technology manner, including but not limited to the smart contracts Kyber and/or 0x mentioned later. The integrated smart contracts in GoodX Wallet currently do not provide cross-chain services.</li>
            <li>19. GoodX Wallet Open-source Code refers to the part of the GoodX Wallet App code publicly announced by the Company, allowing developer users to use (including secondary development) this part of the open-source code in compliance with relevant open-source agreements and the Company's notifications.</li>
            <li>20. Open-source Agreements refer to third-party open-source agreements that developer users must comply with when using the GoodX Wallet open-source code.</li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>III.GoodX Wallet Services (referred to as "Services")</h2>
          <ul>
            <li>1. Identity Creation and Recovery: You can use the "Create Identity" and "Recover Identity" buttons provided by GoodX Wallet to create and recover your identity. You can manage multiple wallet addresses across different blockchains under one identity.  </li>
            <li>2. Wallet Import: For virtual currencies supported by GoodX Wallet, you can generate a new wallet or import compatible wallets generated by other blockchain systems using the wallet import feature.  </li>
            <li>3. Transfer and Receipt: You can manage virtual currencies through the transfer and receipt functionality of GoodX Wallet. This involves using private keys to electronically sign transactions and modify the relevant blockchain ledgers. Transfer refers to the act of transferring funds using the recipient's ENS domain or blockchain address, and the transaction is recorded on the distributed ledger of the respective blockchain system (rather than the actual delivery or transfer of virtual currencies on GoodX Wallet).  </li>
            <li>4. Digital Token Management: You can add, store, or remove digital tokens (excluding ETH and BTC) supported by GoodX Wallet through the user interface.  </li>
            <li>5. Instant Exchange (IEX): Users can engage in and complete virtual currency exchanges between each other on the Kyber smart contract. GoodX Wallet serves as an interface tool to facilitate user interaction with the smart contract and display the corresponding exchange results between users.  </li>
            <li>6. Token Swap (DEX): Users can exchange virtual currencies with the use of the 0x smart contract. GoodX Wallet acts as an interface tool to facilitate user interaction with the smart contract and display the corresponding token swap results.  </li>
            <li>7. DApp Browsing: Users can browse and use DApps through GoodX Wallet.  </li>
            <li>8. DApp Search: Users can search for DApps listed on GoodX Wallet using the search box.  </li>
            <li>9. Integration with Hardware Wallets: Users can match the appropriate version of GoodX Wallet with specific hardware wallets and use the hardware wallet to confirm transactions performed through GoodX Wallet.  </li>
            <li>10. Biometric Payments: Users can utilize the biometric payment feature of GoodX Wallet, which securely encrypts the user's wallet password and stores it in the Keychain/Keystore of their mobile device. During transactions, the user's biometric authentication (fingerprint or facial recognition) is used for quick payment and signing.  </li>
            <li>11. Developer Mode: For developer users, the developer mode allows browsing of any other website by entering the URL in the search box.  </li>
            <li>12. Transaction History: We will copy all or part of your transaction history from the blockchain system. However, users should rely on the latest transaction records from the blockchain system.  </li>
            <li>13. Service Suspension: You acknowledge that due to the "irreversibility" of transactions on the blockchain system, we cannot withdraw or cancel transaction operations. However, in certain circumstances, we may suspend or restrict a user's access to the GoodX Wallet app.  </li>
            <li>14. Other services deemed necessary by our company.  </li>
          </ul>
          <p><strong><span class="decoration">By accepting the aforementioned services provided by our company, users understand and accept the following:</span></strong>
          </p>
          <ul>
            <li>1. In adherence to the decentralized nature of blockchain and to ensure the security of your virtual currencies, the services provided by our company are decentralized, significantly different from those provided by banking and financial institutions. Users understand and accept that our company does not assume the following responsibilities:
              <ul>
                <li>(1) Storing user wallet passwords (i.e., passwords set when creating/importing wallets), private keys, mnemonic phrases, or Keystore files.</li>
                <li>(2) Recovering user wallet passwords, private keys, mnemonic phrases, or Keystore files. </li>
                <li>(3) Freezing wallets. </li>
                <li>(4) Reporting wallet loss. </li>
                <li>(5) Restoring wallets. </li>
                <li>(6) Transaction rollbacks. </li>
              </ul>
            </li>
            <li>2. You are responsible for safeguarding the mobile device containing GoodX Wallet, backing up GoodX Wallet, and backing up wallet passwords, mnemonic phrases, private keys, and Keystore files. If you lose your mobile device, delete GoodX Wallet without backing it up, delete or lose your wallet, have your wallet stolen, or forget your wallet password, private keys, mnemonic phrases, or Keystore files, our company cannot restore or retrieve your wallet. In case of user error during transactions (such as entering the wrong transfer address or incorrect exchange amounts), our company cannot cancel the transaction and shall not be held responsible.</li>
            <li>3. If you use the biometric payment feature of GoodX Wallet, please ensure that you back up your wallet password. After multiple failed attempts at fingerprint or facial recognition, you will need to enter your wallet password. If you forget your wallet password, you will need to set a new one by importing your mnemonic phrase/private key. Our company does not store your biometric information, such as fingerprints or facial recognition, and shall not be held responsible for it.</li>
            <li>4. The virtual currency management services provided by our company and GoodX Wallet do not include all existing virtual currencies. Please refrain from using GoodX Wallet to operate any virtual currencies not supported by GoodX Wallet.</li>
            <li>5. When using the developer mode on GoodX Wallet to browse other websites or third-party DApps not integrated into GoodX Wallet, users should be aware of the potential security risks associated with the URLs of these websites and third-party DApps and assume all risks and consequences independently.</li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>IV. Your Rights and Obligations</h2>
          <ul>
            <li>
              <p>1.<strong> Wallet Creation or Importation</strong></p>
              <ul>
                <li>1. Wallet Creation or Importation: You have the right to create and/or import a wallet through GoodX Wallet on your mobile device. You have the right to set wallet-related information such as the wallet password and the ability to perform transactions such as transfers and receipts on the blockchain using your own wallet through the GoodX Wallet application.</li>
                <li>2. Identity Verification: In accordance with relevant laws and regulations, certain users must complete the required identity verification promptly as prompted by GoodX Wallet when using the related services provided. This may involve providing personal information such as your name, identification number, phone number, bank account number, and other required information. Failure to do so may result in the specific user being unable to use the relevant services, and any resulting losses due to the specific user's delay shall be borne by you.</li>
                <li>3. Different App Versions: Our company may develop different versions of the App for different terminal devices. You should select and install the appropriate version according to your actual needs. If you obtain this App from an unauthorized third party or install a program with the same name as this App but unauthorized, our company cannot guarantee the normal functioning or security of such App. Therefore, any resulting losses shall be borne by you.</li>
              </ul>
            </li>
            <li>
              <p>2.<strong>Usage</strong></p>
              <ul>
                <li>1. Safeguarding Wallet Information: You are responsible for properly safeguarding your mobile device, wallet password, private keys, mnemonic phrases, Keystore files, and other related information. Our company is not responsible for safeguarding this information on behalf of the users. Any risks, liabilities, losses, expenses arising from the loss of your mobile device or hardware wallet, voluntary or involuntary disclosure, forgetting wallet password, private keys, mnemonic phrases, Keystore files, or being attacked or defrauded by others shall be borne by you.</li>
                <li>2. GoodX Wallet Information Prompts: You understand and agree to follow the information prompts published by our company on GoodX Wallet and to perform operations accordingly. Otherwise, any risks, liabilities, losses, expenses, etc., arising from this shall be borne by you.</li>
                <li>3. Awareness of Third-Party Services: You understand and acknowledge that GoodX Wallet does not have the obligation to conduct due diligence on third-party services. You should carefully evaluate and assume all risks associated with using GoodX Wallet.</li>
                <li> 4. Providing Information and Documents: If our company deems it necessary to obtain relevant information from users in order to comply with applicable laws or regulations related to the use or operation of GoodX Wallet, you should promptly provide such information as required by our company. You understand and accept that our company may limit, suspend, or terminate your use of GoodX Wallet until you provide the information that satisfies the company's requirements. You undertake to promptly inform our company of any changes to the documents and information provided to our company based on this agreement. Without written notice of any changes, our company has the right to assume that the content of the documents and information provided by you is true, accurate, free of misleading information, and unchanged.</li>
                <li> 5. Completion of Identity Verification: When our company reasonably believes that your transaction behavior or transaction circumstances are abnormal, or when there are doubts about your identity information, or when our company believes that it is necessary to verify your valid identification documents or other necessary documents, please cooperate actively with our company to verify your valid identification documents or other necessary documents and promptly complete the related identity verification.</li>
                <li>6. Transfers:(1) Daily Transfer Limit: You understand that the daily transfer limit and transaction count available for you to use in GoodX Wallet services may vary due to factors such as the country/region you are using the transfer service in, regulatory requirements, transfer purposes, GoodX Wallet's risk controls, identity verification, and other reasons (2) Irreversibility of Blockchain Transactions: You understand that blockchain transactions performed through GoodX Wallet have an "irreversible" attribute. Therefore, you are solely responsible for any consequences resulting from your operational mistakes, including but not limited to entering an incorrect transfer address or selecting an unreliable transfer node server.
                  (3) Unavailability of Transfer Function: You acknowledge that the following circumstances may cause the transfer function to be unavailable, result in a "transaction failure," or "transaction timeout" when using GoodX Wallet services:
                  a) Insufficient wallet balance;
                  b) Insufficient transaction miner fees;
                  c) Failure in executing smart contract code on the blockchain;
                  d) Exceeding the payment limit set by regulatory authorities, GoodX Wallet, or applicable laws and regulations;
                  e) Technical failures such as network or device issues;
                  f) Abandonment of transactions due to congestion or malfunctions in the blockchain network;
                  g) Your address or the counterparty's address being identified as a special address, such as a high-risk address.
                  (4) Completion of Service Obligations: You understand that GoodX Wallet solely provides you with transfer tools. Once you have completed a transfer using GoodX Wallet, our company has fulfilled all obligations for that particular service. Our company shall not assume any responsibility for any other related disputes or controversies.
                </li>
                <li>7. Instant Exchange and Coin-to-Coin Conversion: You acknowledge that the daily limits for instant exchange and coin-to-coin conversion are set and adjusted by our company. These limits restrict the amount you can perform instant exchanges or coin-to-coin conversions on a daily basis.</li>
                <li>8. Legality and Compliance: You understand that when conducting operations on GoodX Wallet or engaging in transactions using DApps or smart contracts on GoodX Wallet, you must comply with relevant laws, regulations, and national policies.</li>
                <li>9. Notices: GoodX Wallet will send notifications to you through website announcements, emails, message center information, pop-up prompts, or client notifications. These notifications may include updates on transaction progress or reminders for relevant operations. Please pay attention to these notifications in a timely manner.</li>
                <li>10. Service Fees and Tax Obligations:(1) When using GoodX Wallet's instant exchange and coin-to-coin conversion services, the smart contract will charge you transaction fees and/or service fees. The specific fees will be determined by the amount charged by the smart contract.
                  (2) Currently, our company does not charge you any form of service fees. If we introduce fees for certain services in the future, we will provide separate agreements or publish rules regarding the fees.
                  (3) When transferring funds using GoodX Wallet, you are responsible for paying "miner fees" or network fees, the amount of which is determined by you and collected by the relevant blockchain network.
                  (4) You understand that in certain situations (including but not limited to insufficient "miner fees" or network fees paid for the transfer, or instability in the relevant blockchain network), your transfer operation may fail. In such cases, even if your transfer operation is not completed, you may still be charged miner fees by the relevant blockchain network.
                  (5) You are responsible for all taxes and other expenses incurred as a result of your transactions using GoodX Wallet.
                </li>
              </ul>
            </li>
            <li>
              <p>3.<strong>Development by Users Based on GoodX Wallet Open Source Code</strong></p>
              <ul>
                <li class="deepest-list">a) Developer users have the right to use the GoodX Wallet open source code published through official channels for the secondary development of applications, and to download and use any patches or vulnerability fixes released by our company for the GoodX Wallet open source code.
                </li>
                <li class="deepest-list">b) When using the GoodX Wallet open source code, developer users acknowledge and agree to the following:
                  <ul>
                    <li> If developer users discover any vulnerabilities, defects, or improvement suggestions for the GoodX Wallet open source code, they should promptly contact and inform our company and cease any harmful use (if applicable).
                    </li>
                    <li> The GoodX Wallet open source code may include third-party open source agreements and source code. Our company does not provide any guarantees regarding the functionality, virus-free nature, or absence of vulnerabilities of such third-party open source agreements and source code. Developer users should make their own judgments and evaluations of the consequences of using the GoodX Wallet open source code. Developer users should carefully read and comply with the relevant open-source app agreements (including but not limited to the Apache License: http://www.apache.org/licenses/LICENSE-2.0.html) and the requirements notified by our company from time to time.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </section>
        <section class="terms-area strong">
          <h2>V.Risk Warning</h2>
          <ul>
            <li>1. You understand that due to the lack of comprehensive legal and regulatory frameworks in the field of virtual currencies, virtual currencies in this field may involve significant risks such as non-redeemable value and technological instability. You also understand that the price fluctuations of virtual currencies are much higher than those of other financial assets. We strongly advise you to choose to hold or dispose of any virtual currency in a reasonable manner based on your financial condition and risk tolerance. You also understand that the market view function provided by GoodX Wallet only retrieves search results for virtual currency exchange rates from selected exchanges and does not represent the latest market conditions or best prices.</li>
            <li>2. When using GoodX Wallet services, if you or your counterparty fail to comply with this agreement, relevant website instructions, transaction pages, or operation rules, our company does not guarantee the smooth completion of the transaction and does not assume any liability for damages. In such cases, if funds have already been credited to your or your counterparty's GoodX Wallet or a third-party wallet, you understand the irreversible nature of blockchain operations and the "irreversible" characteristics of related transactions, and you and your counterparty bear the corresponding risk consequences.</li>
            <li>3. When transferring virtual currencies to other wallet addresses, you should independently determine whether the counterparty is a person with full legal capacity for civil acts and decide whether to engage in transactions or transfer to the counterparty.</li>
            <li>4. During the transfer process, if you encounter abnormal messages such as "transaction failed" or "timeout for packaging," you should confirm again through the official channels of the relevant blockchain system or other blockchain query tools to avoid duplicate transfers. Otherwise, you will be responsible for any losses and fees incurred as a result.</li>
            <li>5. You understand that after creating or importing a wallet on GoodX Wallet, your keystore, private key, mnemonic phrase, and other information are only stored on the current mobile device and not on GoodX Wallet or our company's servers. You can follow the instructions provided by GoodX Wallet to synchronize your wallet or switch to another mobile device. However, if you fail to save or backup your wallet password, private key, mnemonic phrase, keystore, etc., and your mobile device is lost, your digital tokens will be lost, and our company cannot recover them for you. If you leak or expose the wallet password, private key, mnemonic phrase, keystore, or if the device or server where you store or backup the aforementioned information is compromised or controlled by hackers, your virtual currencies will be lost, and our company cannot recover them for you. You will be solely responsible for any losses incurred in these situations.</li>
            <li>6. We recommend that you securely backup your wallet's password, private key, mnemonic phrase, keystore, and other information when creating or importing a wallet. We advise against using electronic backup methods such as screenshots, emails, notepad applications on your phone, text messages, WeChat, or QQ. We recommend that you copy the mnemonic phrase, keystore, and other information onto a paper notebook and consider storing the electronic data in a password manager.</li>
            <li> 7. We advise you to:(1) Confirm that the hardware wallet you purchase is compatible with the GoodX Wallet version.
              (2) Purchase a brand new hardware wallet from official channels.
            </li>
            <li>8. We kindly remind you to use the biometric payment feature of GoodX Wallet with caution. We suggest that before using it, you:
              (1) Understand the security level of the biometric identification on your mobile device.
              (2) Do not enable the password-free payment feature on public phones.
              (3) Avoid enabling the password-free payment feature if there is a significant amount of digital assets in your wallet.
            </li>
            <li>9. We recommend using GoodX Wallet in a secure network environment and ensuring that your mobile device is not jailbroken or rooted to avoid potential security risks.</li>
            <li>10. Please remain vigilant against fraudulent activities while using GoodX Wallet. If you notice any suspicious behavior, we encourage you to report it to us promptly.</li>
          </ul>
        </section>
        <section class="terms-area strong">
          <h2>VI.Changes, Interruptions, and Termination of Services</h2>
          <ul>
            <li>1. You understand and agree that the company may, at its discretion, temporarily provide partial service functions or suspend certain service functions or introduce new service functions in the future. When we make changes to the services, as long as you continue to use GoodX Wallet, it means you still agree to this agreement or the revised terms of this agreement.</li>
            <li>2. In order to avoid any misuse of GoodX Wallet or any security risks related to virtual currencies, you should refrain from using GoodX Wallet without having a basic understanding of blockchain. The company reserves the right to refuse to provide partial or all service functions to users who do not have a basic understanding of blockchain.</li>
            <li>3. You understand that the company will suspend the services (or terminate the services entirely in the event of the following circumstances):
              <ul>
                <li class="deepest-list"> Temporary interruption of GoodX Wallet operation due to technical reasons such as device maintenance, blockchain system upgrades, malfunctions, or communication interruptions.
                </li>
                <li class="deepest-list">Inability to provide services due to force majeure events such as typhoons, earthquakes, tsunamis, floods, power outages, wars, or terrorist attacks, computer viruses, trojans, hacker attacks, system instability, or government actions, or when the company reasonably believes that continuing to provide services would entail significant risks.
                </li>
                <li class="deepest-list">Significant unfavorable changes in applicable laws or policies.</li>
                <li class="deepest-list"> Other circumstances beyond the control or reasonable foresight of the company.</li>
              </ul>
            </li>
            <li>4.The company may unilaterally suspend or terminate the use of GoodX Wallet, in part or in whole, by the user in the following circumstances:
              <ul>
                <li>(1) User's death.</li>
                <li>(2) Theft of someone else's wallet information or mobile device.</li>
                <li>(3) Providing false personal information in GoodX Wallet.</li>
                <li>(4) Refusing the mandatory update operation of GoodX Wallet.</li>
                <li>(5) Violating third-party open-source licenses or the provisions notified by the company regarding the use of GoodX Wallet source code.</li>
                <li>(6) Using GoodX Wallet for illegal or criminal activities.</li>
                <li>(7) Impeding other users' normal use of GoodX Wallet.</li>
                <li>(8) Falsely claiming to be a company employee or administrator.</li>
                <li>(9) Attacking, intruding, altering, or threatening the normal operation of the company's computer systems in any other way.</li>
                <li>(10) Utilizing GoodX Wallet for spam advertising.</li>
                <li>(11) Spreading rumors that damage the company's and GoodX Wallet's reputation.</li>
                <li>(12) Engaging in illegal activities, other behaviors that violate this agreement, or situations where the company reasonably believes that the functionality should be suspended.</li>
              </ul>
            </li>
            <li>5. When the company makes changes, interruptions, or terminations to the services, you have the right to export your wallet and other information within a reasonable time.
            </li>
          </ul>
        </section>
        <section class="terms-area strong">
          <h2>VII.Your Statements and Representations</h2>
          <ul>
            <li>1. You shall comply with the laws and regulations of the country or region where you reside and shall not use GoodX Wallet for any illegal purposes or in any illegal manner.
            </li>
            <li>2. You are not a person excluded from using GoodX Wallet services due to ineligibility.</li>
            <li>3. You shall not engage in any illegal or criminal activities using GoodX Wallet, including but not limited to:
              <ul>
                <li>(1) Engaging in any illegal activities, such as money laundering or illegal fundraising.</li>
                <li>(2) Accessing the company's services, collecting or processing the content provided by the company, or interfering or attempting to interfere with any user through the use of any automation program, app, engine, web crawler, web analytics tool, data mining tool, or similar tools.</li>
                <li>(3) Providing gambling information or enticing others to participate in gambling in any way.</li>
                <li>(4) Intruding into others' GoodX Wallets to steal digital tokens.</li>
                <li>(5) Engaging in transactions that do not align with the transaction content claimed by the counterparty or engaging in untrue transactions.</li>
                <li>(6) Engaging in any actions that infringe upon or may infringe upon the GoodX Wallet service system or data.</li>
                <li>(7) Engaging in any other behavior that the company has reasonable grounds to believe is inappropriate.</li>
              </ul>
            </li>
            <li>4. You understand and agree that if you violate relevant laws (including but not limited to customs and/or tax regulations) or the provisions of this agreement, causing any loss to the company, claims from any third party, or penalties from any administrative authority, you shall compensate the company, including reasonable attorney fees.</li>
            <li>5. You undertake to pay the company's service fees (if any) in a timely manner, otherwise the company has the right to suspend the services provided to you.</li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>VIII.Privacy Policy</h2>
          <p>Any data or information you provide to us is also subject to our Privacy Policy ("GoodX Wallet Privacy Policy"), which is part of this agreement.</p>
        </section>
        <section class="terms-area strong">
          <h2>IX. Disclaimer and Liability Limitation</h2>
          <ul>
            <li>
              <p>1. The company shall only be responsible for the obligations stated in this agreement.</p>
            </li>
            <li>
              <p>2. You understand and agree that, to the maximum extent permitted by law, the company can only provide GoodX Wallet services based on the existing technical level and conditions. The company shall not be held responsible for any failure of GoodX Wallet to provide services due to the following reasons:</p>
              <ul>
                <li>(1) GoodX Wallet system maintenance or upgrades;</li>
                <li>(2) Force majeure events such as typhoons, earthquakes, floods, lightning, or terrorist attacks;</li>
                <li>(3) Malfunctions in your mobile device's software, hardware, communication lines, or power supply lines;</li>
                <li>(4) Improper operation or unauthorized use of the company's services without authorization or approval;</li>
                <li>(5) Reasons such as computer viruses, trojans, malicious program attacks, network congestion, system instability, system or equipment failures, communication failures, power failures, or government actions;</li>
                <li>(6) Any other reasons not caused by the company.</li>
              </ul>
            </li>
            <li>
              <p>3. The company shall not be held responsible for the following circumstances:</p>
              <ul>
                <li>(1) Loss of virtual currency due to the user's loss of mobile device, deletion of GoodX Wallet without backup, deletion of wallet without backup, forgetting wallet password, private key, mnemonic phrase, or Keystore;</li>
                <li>(2) Loss of virtual currency due to the user's own disclosure of wallet password, private key, mnemonic phrase, or Keystore, or the borrowing, transfer, or authorization of others to use their mobile device or GoodX Wallet, or the use of GoodX Wallet application downloaded through unofficial channels or in other insecure manners;</li>
                <li>(3) Loss of virtual currency due to user's misoperation (including but not limited to entering incorrect transfer addresses or selecting inappropriate transfer node servers) in GoodX Wallet;</li>
                <li>(4) Loss of digital tokens due to user's lack of understanding of blockchain technology and resulting misoperations;</li>
                <li>(5) Deviation in transaction records copied by the company from the blockchain due to system delays, blockchain instability, or other reasons.</li>
              </ul>
            </li>
            <li>
              <p>4. Each user shall bear the risks and consequences arising from the following circumstances:</p>
              <ul>
                <li>(1) Transactions conducted through third-party services;</li>
                <li>(2) User's use of developer mode.</li>
              </ul>
            </li>
            <li>5. You understand that GoodX Wallet is merely a tool for managing your virtual currency. The company cannot control the quality, security, or legality of third-party services, the authenticity or accuracy of information, or the ability of counterparties to fulfill their obligations under the agreements entered into between you and them. You decide whether to use third-party services. You understand that the transaction and enforceable contractual relationship are established between you and your counterparties and are unrelated to the company. The company advises you to exercise caution and assess the authenticity, legality, and effectiveness of the information provided by third-party services. Furthermore, you assume all risks associated with transactions with any third parties.</li>
            <li>6. You acknowledge that the company may provide services to you and your counterparties, have a relationship or other vested interests with them, and you agree to explicitly waive any actual or potential conflicts of interest arising from such actions by the company. You shall not assert any legal defects in the company's provision of services or increase the company's liability or duty of care based on this.</li>
            <li>
              <p>7. The company does not provide the following guarantees:</p>
              <ul>
                <li>(1) The services provided by the company will meet all your requirements;</li>
                <li>(2) Any technology, products, services, information, or other materials obtained through the company's services will meet your expectations;</li>
                <li>(3) The timeliness, accuracy, completeness, and reliability of the information such as digital token market transactions obtained by the company from third-party exchanges;</li>
                <li>(4) Timely performance of contractual obligations by your trading counterparties on GoodX Wallet.</li>
              </ul>
            </li>
            <li>8. Under any circumstances, the total liability of the company for breach of this agreement shall not exceed either (1) the market value of 0.1 Ether or (2) USD 100, whichever is higher.</li>
            <li>9. You understand that GoodX Wallet is only a tool for managing virtual currency and displaying transaction information. The company does not provide legal, tax, or investment advice. You should seek advice from legal, tax, and investment professionals, and the company shall not be held responsible for any investment losses, data losses, or other damages suffered by you during the use of our services.</li>
            <li>10. You understand that, in accordance with applicable laws and regulations, we may change our user admission criteria, limit the scope and manner of providing services to specific users, and make other changes as required.</li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>X.Others</h2>
          <ul>
            <li>1. Non-Transferability: Subject to the terms of this agreement, only you (and not any other person) have the right to assert claims related to the use of the service against us. You shall not transfer, trade, or attempt to transfer or trade your rights to such claims. Any such transfer is invalid, and we have no obligations or liabilities towards the transferee.</li>

            <li> 2. Intellectual Property: GoodX Wallet is an application developed and owned by our company, Vaultech Ltd, with intellectual property rights. The intellectual property rights to any content displayed in GoodX Wallet (including this agreement, notices, articles, videos, audios, images, files, information, data, trademarks, or logos) belong to our company or third-party rights holders. Users are only allowed to use the GoodX Wallet application and its content for the purpose of holding and managing virtual currencies. Without prior written consent from our company or the relevant third-party rights holders (or as permitted by the applicable open-source licenses), no one is authorized to use, modify, reverse engineer, copy, publicly distribute, alter, disseminate, publish, or disclose the aforementioned application and content (including the GoodX Wallet open-source code). This agreement does not grant you any intellectual property rights, including the right to use any information, images, user interfaces, logos, trademarks, trade names, domain names, or copyrights associated with us or the service for any purpose.</li>

            <li>3. Non-Waiver: Our failure to enforce any provision of this agreement or to assert any right, claim, or cause of action under this agreement shall not be deemed as a waiver of our right to assert any rights, claims, or causes of action against you.</li>

            <li>4. Entire Agreement:
              (1) This agreement consists of the "GoodX Wallet Service Agreement," "GoodX Wallet Privacy Policy," and all the rules published by our company from time to time (including the content in the "Help Center").
              (2) Except for the "GoodX Wallet Service Agreement" and "GoodX Wallet Privacy Policy" contained in this agreement, this agreement constitutes the entire agreement and understanding between us regarding the use of GoodX Wallet and related services, and supersedes all prior (oral and written) agreements, understandings, or arrangements.</li>

            <li>5. Taxes:
              (1) The use of services under this agreement does not include any taxes ("Tax Obligations") applicable, incurred, or related to your use of the service in any jurisdiction.
              (2) You are responsible for determining any Tax Obligations and for properly declaring, withholding, collecting, reporting, and remitting the correct Tax Obligations to the relevant tax authorities. You shall bear all penalties, claims, fines, sanctions, and other liabilities arising from your failure to complete or fulfill any of your obligations related to Tax Obligations.
              (3) We are not responsible for determining any Tax Obligations or for declaring, withholding, collecting, reporting, and remitting the correct Tax Obligations to the relevant tax authorities.</li>

            <li>6. Governing Law and Dispute Resolution:
              (1) This agreement shall be governed by English law and shall be interpreted accordingly.
              (2) Any dispute arising out of or in connection with this agreement (including any question regarding its existence, validity, or termination) shall be first attempted to be resolved amicably. If the dispute is not resolved within 30 days from the start of the reconciliation process, the dispute shall be submitted to the English courts.</li>

            <li>7. Severability and Partial Invalidity:
              (1) If any part of this agreement is deemed invalid, illegal, or unenforceable by any jurisdiction governing this agreement, it shall only be considered invalid, illegal, or unenforceable to that extent (and not further expanded). The remaining provisions of this agreement shall remain valid and in full force.
              (2) The invalidity, illegality, or unenforceability of any provision of this agreement in any jurisdiction shall not affect the validity, legality, or enforceability of that provision under the laws of other jurisdictions, nor shall it affect the validity, legality, or enforceability of the other provisions of this agreement.</li>

            <li>8. Translation of the Agreement: Any translation of this agreement (if provided) is for convenience purposes only and shall not modify the English version of this agreement. In case of any conflict between the English version and the non-English version of this agreement, the English version shall prevail.</li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>XI.Miscellaneous</h2>
          <ul>
            <li>1. You shall fully understand and comply with all applicable laws, regulations, and rules in your jurisdiction regarding the use of the service.</li>
            <li>2. If you encounter any issues during the use of the service, you can contact us through the feedback submission or other means provided in GoodX Wallet.</li>
            <li>3. All users can view this agreement in GoodX Wallet. We encourage you to review this agreement each time you access GoodX Wallet.</li>
            <li> 4. This agreement comes into effect on May 8, 2023.</li>
          </ul>

        </section>
        <p class="right"><strong>Any matters not covered by this agreement shall be subject to the announcements and relevant rules updated by our company from time to time.</strong></p>
        <p class="right"><strong>Vaultech Ltd.</strong></p>

      </section>
      <section class="wrapper">
        <h1 id="privacy-policy">Privacy Policy for GoodX Wallet</h1>
        <p class="mb"><strong>Last Updated: May 8, 2023</strong></p>
        <p class="mb"><strong>Dear Users,</strong></p>
        <p class="mb">
          <strong>Vaultech Ltd. (referred to as "the Company" or "we") respects and protects the privacy of users (referred to as "you" or "users"). In accordance with this Privacy Policy (referred to as "this Policy"), we collect, disclose, and process your personal information when you engage in certain actions. We recommend that you carefully read and understand the entire contents of this Policy before using the application. Important information, including disclaimers, will be highlighted in bold text. The key terms and definitions in this Policy are consistent with the "GoodX Wallet Terms of Service" by the Company. In the event of any inconsistency between the definitions or terms used in this Policy and the "GoodX Wallet Terms of Service," this Policy shall prevail. By providing us with your personal information, you agree to the collection, use, disclosure (including transmission), and processing of your personal information in accordance with this Policy. If you do not accept this Policy, please do not provide us with any personal information. This Policy may be updated by the Company online at any time without notice. The updated Policy will become effective upon its publication in our application and will replace the previous policy. The modified Policy will immediately apply to the personal information you provide to us. If you do not agree to the modified terms, please immediately stop using the application. Your continued use of the application will be deemed as acceptance of the modified Policy.</strong></p>
        <section class="terms-area">
          <h2>1. What information we collect from you:</h2>
          <ul>
            <li>1. We collect your personal information, including but not limited to your mobile device information, activity logs, transaction records, and wallet addresses.</li>
            <li>2. To meet your specific service needs, we collect your personal information, including but not limited to your name, bank card number, mobile phone number, email address, etc.</li>
            <li>3. Please note that your wallet password, private key, mnemonic phrase, and Keystore are not stored or synchronized to our servers. We do not provide services to recover your wallet password, private key, mnemonic phrase, or Keystore.</li>
            <li>4. We may request additional personal information from you to enable you to use certain specific features of the application. If you do not agree to provide such personal information, it will be deemed that you have waived the use of specific features of the application.</li>
            <li>5. We collect information in the following ways:
              <ul>
                <li>(1) When you actively provide information to us, regardless of the reason.</li>
                <li>(2) When you authorize us to obtain your information from third parties.</li>
                <li>(3) When you use our application and services.</li>
                <li>(4) When you contact or interact with our employees through various communication channels, such as social media platforms, communication platforms, face-to-face meetings, telephone, email, fax, and postal mail.</li>
                <li>(5) When you engage in transactions with us, contact us, or request us to contact you.</li>
                <li>(6) When you request to subscribe to our emails.</li>
                <li>(7) We copy all or part of your transaction records through the blockchain system. However, your transaction records should be based on the records of the blockchain system.</li>
              </ul>
            </li>
            <li>6. Our application may contain technologies that collect personal information. Specific collection methods will be described in this Policy (see Section Five) or applicable terms and conditions.</li>
            <li>7. Providing us with personal information is your voluntary act, and you can withdraw your consent at any time. However, if you choose to refuse to provide us with the personal information we require, you may not be able to use the application, and we may not be able to contact you or provide you with the products or services you need.</li>
            <li>8. In certain situations, you may provide us with personal information of individuals other than yourself. If you provide information about others, you must ensure that you have obtained the consent of those individuals to allow us to collect their personal information and that they have consented to you disclosing their personal information to us. You agree that if such individuals make claims against us regarding the collection, use, and disclosure of their personal information in accordance with this Policy, you will compensate us and hold us harmless.</li>
            <li>9. You must ensure that the personal information you provide to us is true, accurate, and complete. You should notify us of any changes to your personal information.</li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>How we use your information:</h2>
          <ul>
            <li>1. We collect, use, or disclose your personal information for one or more of the following purposes:
              <ul>
                <li>(1) Provide you with the products and/or services you request.</li>
                <li>(2) Process your relationship with us.</li>
                <li>(3) Enhance your user experience.</li>
                <li>(4) Confirm the correspondence between your mobile device and your wallet using the unique serial number of your mobile device.</li>
                <li>(5) Send you timely important notifications, such as software updates, changes to service agreements, and changes to this Policy.</li>
                <li>(6) Assist you in handling your inquiries, feedback, complaints, and requests through wallet addresses and provided mobile device information.</li>
                <li>(7) Inform you about our products, services, projects, and activities.</li>
                <li>(8) Resolve disputes, investigate any complaints, claims, or disputes, or any actual or potential illegal activities.</li>
                <li>(9) Conduct internal audits, data analysis, and research within the Company.</li>
                <li>(10) Perform user behavior analysis by tracking your usage of the application.</li>
                <li>(11) Comply with legal and regulatory requirements and requests from regulatory authorities.</li>
                <li>(12) Comply with international legal and regulatory requirements for security, anti-money laundering, or counter-terrorism.</li>
                <li>(13) Fulfill our obligations and contractual terms and conditions.</li>
                <li>(14) Any reasonable purposes related to the above purposes.</li>
              </ul>
            </li>
            <li>2. With your consent, we may use your personal information for other purposes, such as informing you about the latest activities, offers, and promotions introduced by our strategic partners or affiliates.</li>
            <li>3. In the "Usage Settings" of GoodX Wallet, we provide you with the option for "Fingerprint or Face ID Login" to conveniently manage your virtual currencies.</li>
            <li>4. In GoodX Wallet, we provide you with the option for "Biometric Authentication Payment." Biometric authentication payment securely encrypts your wallet password and stores it in the Keychain/Keystore of your mobile device. When making transactions, your biometric authentication (fingerprint or face recognition) is used for quick payment and signing.</li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>3 How do you control your information?</h2>
          <p>You have the following independent control over your personal information in the application:</p>
          <ul>
            <li>1. You can import your other wallets into the application by synchronizing wallets, or you can export your wallet in the application to other cryptocurrency management wallets. The application will display the information of the imported wallet.</li>
            <li>2. You are aware that you can modify your virtual currency types, perform transfers and receipts, and engage in other activities related to virtual currency in the "Assets" section.</li>
            <li>3. You are aware that in the "Me" section of the application, you have the freedom to choose the following actions:(1) In "Usage Settings," you can choose not to enable the "Fingerprint or Face ID login" option, which means you can choose not to use the Touch ID or Face ID authentication service.
              (2) In "Submit Feedback," you can provide us with your feedback on issues and suggestions for improvement in the application. We are pleased to communicate with you and actively improve our services.
            </li>
            <li>4. You are aware that you can choose not to enable the "Biometric Payment" option, which means you can choose not to invoke your biometric (fingerprint or face) authentication during transactions. </li>

            <li>5. You are aware that you and we do not have control over whether your transaction records are public. Due to the open-source nature of blockchain transaction systems, your transaction records are publicly transparent throughout the entire blockchain system. </li>

            <li>6. Our application will include links that can connect to other websites, applications, or smart contracts that are not owned, operated, developed, or maintained by us. These links are provided for your convenience. This policy applies only to our application. When you use third-party services, you are aware that our "GoodX Wallet Service Agreement" and "GoodX Wallet Privacy Policy" will no longer apply. We recommend that you read and understand their privacy rules and related user service agreements in detail. </li>

            <li>7. You have the right to request us to update, change, delete your personal information, and/or withdraw your consent given to us. If you wish to withdraw your consent previously given to us, or if you wish to update, change, delete, or view the personal information we hold, or if you do not accept the modifications to this policy, you can contact our official customer service. </li>

            <li>8. In order for you to manage the personal information held by us or to provide you with information about our use (or potential use) of your personal information within the year prior to your request, we may charge you a certain fee. If we charge you a fee, we will inform you of the amount in advance and fulfill your request after you have made the payment. We will strive to respond to your request within thirty (30) days after you make the request. If we cannot respond within thirty (30) days, we will inform you of the specific response time. </li>

            <li>9. In many cases, we need to obtain your personal information in order to provide you with the products or services you request. If you choose to refuse to provide us with the personal information we require or withdraw your consent previously given to us, we may not be able to continue providing you with the products and services you requested. </li>

          </ul>
        </section>
        <section class="terms-area">
          <h2>4 We may share or transfer your information</h2>
          <ul>
            <li>1. We will store your personal information for business and legal purposes.</li>
            <li>2. Without your consent, we will not sell, trade, or transfer your personal information to third parties.</li>
            <li>3. If you agree to disclose your personal information to strategic partners or affiliates, we may disclose your personal information to such entities. Such entities will only use your personal information for the purposes you have agreed to.</li>
            <li>
              <p>4. You agree that we may disclose or share your personal information with the following third parties:</p>
              <ul>
                <li class="deepest-list">(1) Service providers and data processors who work on our behalf and provide services to us, such as KYC checks, accounting, data processing or management services, website hosting, maintenance and operation services, email messaging services, analytics services, payment transaction processing, marketing, etc.; and
                </li>
                <li class="deepest-list">(2) Our consultants and professional advisors, such as accountants, lawyers, auditors.
                </li>
              </ul>
            </li>
            <li>
              <p>5. If we need to transfer your personal information to any other country for the purposes mentioned above, we will obtain your consent and ensure that the recipient of the personal information has the same level of personal information protection measures as us. If there is no applicable personal information protection law in that country or region that applies to our relationship with you, we will enter into a legally enforceable agreement with the personal information recipient.</p>
            </li>
            <li>
              <p>6. Without your prior consent, our company will not share or transfer your personal information to any third party, except in the following circumstances:</p>
              <ul>
                <li>(1) The collected personal information is voluntarily disclosed by you to the public;</li>
                <li>(2) The collected personal information is collected from legally publicly disclosed information, such as lawful news reports, government information disclosure channels, etc.;</li>
                <li>(3) Providing or executing this policy to protect our or others' rights, property, or safety as required by applicable laws, regulations, legal procedures, administrative or judicial authorities;</li>
                <li>(4) In the event of a merger or acquisition involving the transfer of personal information, our company will require the personal information recipient to continue to be bound by this policy.</li>
              </ul>
            </li>
          </ul>
        </section>
        <section class="terms-area" id="automatic-data-collection">
          <h2>5 Automatic Data Collection Technologies</h2>
          <ul>
            <li>We use automatic data collection technologies in our application, including:
            </li>
            <li class="deepest-list">1. Cookies: Cookies are small text files that are set by website or application operators to identify your browser or device. We may use cookies in our application to store and track information such as the number of users, usage frequency, user distribution, and online preferences. Cookies do not capture information that can identify your identity, but the information collected can help us analyze the usage of the application to enhance your user experience. You can disable cookies in your browser settings, but this may affect the functionality of the application.
            </li>
            <li class="deepest-list">2. Web Analytics: Web analytics is a method of collecting and evaluating the behavior of website and mobile application visitors. This includes analyzing traffic patterns, such as determining the frequency of browsing certain parts of the website or mobile application, or understanding the information or services that visitors are most interested in. Our application uses web analytics services provided by third-party tools.</li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>6  How We Protect Your Information</h2>
          <ul>
            <li>1. In the event that our company ceases operations, we will cease to collect your personal information and take measures to delete or anonymize the personal information we hold within a reasonable period.
            </li>
            <li>2. To protect your personal information, our company will implement data security technologies, enhance internal compliance, provide information security training to employees, and set security browsing permissions for relevant data to ensure the security of your privacy information.</li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>7  Protection of Minors</h2>
          <p>We make the following special provisions for the protection of minors under the age of 18:</p>
          <ul>
            <li>1. Minors should use our company's services under the guidance of their parents or guardians.</li>
            <li>2. We recommend that the parents and guardians of minors guide them in using the application after reading this policy, the "GoodX Wallet Terms of Service," and our other relevant rules.
            </li>
            <li>3. Our application will protect the confidentiality and security of personal information of minors in accordance with applicable laws and regulations.
            </li>
          </ul>
        </section>
        <section class="terms-area strong">
          <h2>8 Disclaimer</h2>
          <ul>
            <li>1. You acknowledge that after you use third-party services, this policy will no longer apply to the collection, use, disclosure, and transfer of your personal information by such third parties. Our company cannot guarantee that such third parties will take reasonable security measures.
            </li>
            <li>2. You will be solely responsible for your use of third-party services, and you agree that if a third party collects, uses, discloses, or transfers your personal information, and you suffer losses or damages as a result, you may not hold our company responsible.
            </li>
            <li>3. You acknowledge and accept that, to the maximum extent permitted by applicable law, our company will take reasonable security measures to protect your personal information based on the principles of "as is," "as available," and "without warranty of flawlessness" under the current technological level, in order to avoid the leakage, alteration, or destruction of information. Our company transmits data wirelessly, and therefore, we cannot guarantee the privacy and security of data transmitted over wireless networks.
            </li>
          </ul>
        </section>
        <section class="terms-area">
          <h2>9 Other</h2>
          <ul>
            <li>1. You must fully understand and comply with all relevant laws, regulations, and rules in your jurisdiction regarding the use of our company's services.
            </li>
            <li>2. Applicable law and dispute resolution: This policy is governed by English law and shall be interpreted accordingly.</li>
            <li class="deepest-list">3. You can view this policy and other service rules of our company in our application. We encourage you to review our service agreement and privacy policy each time you visit the application.
            </li>
            <li class="deepest-list">4. Any translated version of this policy is provided for convenience only and does not intend to modify the terms of this policy. In the event of any conflict between the English version and non-English versions of this policy, the English version shall prevail.
            </li>
          </ul>
        </section>
        <p class="center last">
          <strong>This policy is effective from May 8, 2023.</strong></p>
        <p class="right"><strong>For any matters not covered by this policy, you must comply with the announcements and relevant rules issued by our company from time to time.</strong></p>
        <p class="right"><strong>Vaultech Ltd.</strong></p>

      </section>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'UserAgreement',
    components: {},
    props: {},
    data () {
      return {
        lang: this.$route.params.lang || 'en'
      }
    },
    computed: {},
    methods: {},
    mounted () {

    },
  }
</script>

<style>

</style>
